import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ContadorProposicaoModule } from './contador-proposicao/contador-proposicao.module';
import { IconeEmentaModule } from './icone-ementa/icone-ementa.module';
import { PrimeiroAcessoPage } from './primeiro-acesso/primeiro-acesso.page';
import { AgendaComponentPageModule } from './agenda-component/agenda-component.module';
import { TarefaEdicaoModule } from './tarefa-edicao/tarefa-edicao.module';
import { CampoEdicaoModule } from './campo-edicao/campo-edicao.module';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'projetolei',
    loadChildren: () => import('./configuracao-notificacao/configuracao-notificacao.module').then(m => m.ConfiguracaoNotificacaoPageModule)
  },
  {
    path: 'retrospectiva',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  // { path: '', loadChildren: './home/home.module#HomePageModule' },
  { path: 'linkedin', component: PrimeiroAcessoPage },
  { path: 'primeiro-acesso', component: PrimeiroAcessoPage },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'cadastro/:email',
    loadChildren: () => import('./cadastro/cadastro.module').then(m => m.CadastroPageModule)
  },
  {
    path: 'configuracao-notificacao',
    loadChildren: () => import('./configuracao-notificacao/configuracao-notificacao.module').then(m => m.ConfiguracaoNotificacaoPageModule)
  },
  {
    path: 'radar-monitoramento',
    loadChildren: () => import('./radar-monitoramento/radar-monitoramento.module').then(m => m.RadarMonitoramentoPageModule)
  },
  {
    path: 'radar-edicao',
    loadChildren: () => import('./radar-edicao/radar-edicao.module').then(m => m.RadarEdicaoPageModule)
  },
  {
    path: 'radar-share/:id',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'proposicao',
    loadChildren: () => import('./proposicao/proposicao.module').then(m => m.ProposicaoPageModule)
  },
  {
    path: 'proposicao/:id',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'parlamentar',
    loadChildren: () => import('./parlamentar/parlamentar.module').then(m => m.ParlamentarPageModule)
  },
  {
    path: 'meu-plano',
    loadChildren: () => import('./meu-plano/meu-plano.module').then(m => m.MeuPlanoPageModule)
  },
  {
    path: 'comissoes',
    loadChildren: () => import('./comissoes/comissoes.module').then(m => m.ComissoesPageModule)
  },
  {
    path: 'comissao',
    loadChildren: () => import('./comissao/comissao.module').then(m => m.ComissaoPageModule)
  },
  {
    path: 'ranking',
    loadChildren: () => import('./ranking/ranking.module').then(m => m.RankingPageModule)
  },
  {
    path: 'criterio-ranking',
    loadChildren: () => import('./criterio-ranking/criterio-ranking.module').then(m => m.CriterioRankingPageModule)
  },
  // {
  //   path: 'voto-parlamentar',
  //   loadChildren: () => import('./voto-parlamentar/voto-parlamentar.module').then(m => m.VotoParlamentarPageModule)
  // },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then(m => m.ContatoPageModule)
  },
  {
    path: 'prazo',
    loadChildren: () => import('./prazo/prazo.module').then(m => m.PrazoPageModule)
  },
  {
    path: 'autoridades',
    loadChildren: () => import('./autoridades/autoridades.module').then(m => m.AutoridadesPageModule)
  },
  {
    path: 'ordem-dia',
    loadChildren: () => import('./ordem-dia/ordem-dia.module').then(m => m.OrdemDiaPageModule)
  },
  {
    path: 'notificacao-usuario',
    loadChildren: () => import('./notificacao-usuario/notificacao-usuario.module').then(m => m.NotificacaoUsuarioPageModule)
  },
  {
    path: 'processo-aneel-lista',
    loadChildren: () => import('./processo-aneel-lista/processo-aneel-lista.module').then(m => m.ProcessoAneelListaPageModule)
  },
  {
    path: 'redefinirsenha/:idEncoded',
    loadChildren: () => import('./primeiro-acesso/primeiro-acesso.module').then(m => m.PrimeiroAcessoPageModule)
  },
  {
    path: 'ingressarplano/:id',
    loadChildren: () => import('./primeiro-acesso/primeiro-acesso.module').then(m => m.PrimeiroAcessoPageModule)
  },
  {
    path: 'selecao-usuario-plano',
    loadChildren: () => import('./selecao-usuario-plano/selecao-usuario-plano.module').then( m => m.SelecaoUsuarioPlanoPageModule)
  },
  {
    path: 'meus-clientes',
    loadChildren: () => import('./meus-clientes/meus-clientes.module').then( m => m.MeusClientesPageModule)
  },
  {
    path: 'meus-clientes-edicao',
    loadChildren: () => import('./meus-clientes-edicao/meus-clientes-edicao.module').then( m => m.MeusClientesEdicaoPageModule)
  },
  {
    path: 'meus-logs',
    loadChildren: () => import('./meus-logs/meus-logs.module').then( m => m.MeusLogsPageModule)
  },
  {
    path: 'membro-cliente-edicao',
    loadChildren: () => import('./membro-cliente-edicao/membro-cliente-edicao.module').then( m => m.MembroClienteEdicaoPageModule)
  },
  {
    path: 'newsletter-charts',
    loadChildren: () => import('./newsletter-charts/newsletter-charts.module').then( m => m.NewsletterChartsPageModule)
  },  {
    path: 'radar-diario-edicao',
    loadChildren: () => import('./radar-diario-edicao/radar-diario-edicao.module').then( m => m.RadarDiarioEdicaoPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true }), IconeEmentaModule, ContadorProposicaoModule, TarefaEdicaoModule
  ],
  declarations: [],
  exports: [RouterModule, IconeEmentaModule, ContadorProposicaoModule, TarefaEdicaoModule, CampoEdicaoModule]
})
export class AppRoutingModule { }
