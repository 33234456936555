/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, AlertController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PageBaseService {
  public loading: HTMLIonLoadingElement;
  subscription: any;
  i = 0;
  public loadingVisivel = false;
  constructor(
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected platform: Platform = null,
    protected router: Router = null
  ) {
    if (platform && router) {
      this.subscription = this.platform.backButton.subscribe(() => {
        if (this.i > 1) {
          (navigator as any).app.exitApp();
        } else {
          this.i = this.i + 1;
          setTimeout(() => {
            this.i = 0;
          }, 2000);
        }
      });
    }
  }

  buildUrl(parameters) {
    var qs = "";
    for (var key in parameters) {
      var value = parameters[key];
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(element => {
          qs += encodeURIComponent(key) + "=" + element + "&";
        });
      }
      else
        qs += encodeURIComponent(key) + "=" + value + "&";
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1); //chop off last "&"
    }
    return qs;
  }

  capitalize = (s: string) => {
    s = s.toLowerCase();
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  capitalizeAll = function (words: string) {
    const separateWord = words.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  };

  async criarLoading(texto: string) {
    return await this.loadingController.create({
      message: texto,
    });
  }

  ocultarLoading() {
    let qtd = 0;
    setTimeout(() => {
      var interval = setInterval(() => {
        if (this.loading != null) {
          this.loadingVisivel = true;
          this.loadingVisivel = false;
          this.loading.dismiss();
          clearInterval(interval);
        } else {
          qtd++;
        }
        if (qtd == 3) {
          clearInterval(interval);
        }
      }, 1000);
    }, 1000);
  }

  renderizarLoading(texto: string) {
    return new Promise<HTMLIonLoadingElement>(async (resolve) => {
      this.criarLoading(texto).then(async (x) => {
        this.loading = x;
        await this.loading.present();
        resolve(x);
      });
    });
  }

  async exibirLoading(texto: string) {
    try {
      if (!this.loadingVisivel) {
        this.loadingVisivel = true;
        this.loading.dismiss().then(() => {
          this.renderizarLoading(texto);
        });
      }
    } catch (error) {
      this.renderizarLoading(texto);
    }
  }
}
