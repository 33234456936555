import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class RadarClienteService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

 
  listarIdSelecionadoPorRadar(idRadar) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/RadarCliente/ListarIdSelecionadoPorRadar?idRadar=' + idRadar)
        .subscribe(data => {
          resolve(data);
        }, error => {

        });
    });
  }
  
  alterar(Cliente) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/RadarCliente/Atualizar', Cliente)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
  deletar(membro) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/RadarCliente/deletar', membro)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
  inserir(Cliente) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/RadarCliente/Inserir', Cliente)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
}
