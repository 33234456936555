/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ParlamentarService } from '../api/parlamentar.service';
import { VotoParlamentarService } from '../api/voto-parlamentar.service';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { PageBaseService } from '../util/page-base.service';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { UsuarioService } from '../api/usuario.service';
import { ProjetoService } from '../api/projeto.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Share } from '@capacitor/share';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-voto-parlamentar',
  templateUrl: './voto-parlamentar.page.html',
  styleUrls: ['./voto-parlamentar.page.scss'],
})
export class VotoParlamentarPage extends PageBaseService implements OnInit {
  @Input()
  projetoSessaoPlenario: any;
  public columnChart1: GoogleChartInterface;
  public columnChart2: GoogleChartInterface;
  public columnChart3: GoogleChartInterface;
  public columnChart4: GoogleChartInterface;
  listaVoto: any;
  aba = 'dados';
  radarCadastrado = false;
  VotoSim: any;
  VotoNao: any;
  VotoAbstencao: any;
  VotoOutro: any;
  filtroTipoVoto: any;
  filtroPartido = true;
  filtroUf = true;
  listaVotoTodos: any;
  votoSimLista: any;
  votoNaoLista: any;
  votoPartidoLista: any[][];
  partidoListaBkp: any;
  partidoLista: any;
  ufListaBkp: any;
  width = (this.platform.width() as any);
  ufLista: any;
  listaVotoBkp: any;
  nativePlatform: boolean;
  governismoLista: any[][];
  constructor(
    public alertCtrl: AlertController,
    private usuarioService: UsuarioService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private parlamentarService: ParlamentarService,
    private sessaoPlenarioService: SessaoPlenarioService,
    private modalController: ModalController,
    private votoParlamentarService: VotoParlamentarService,
    private projetoService: ProjetoService,
    protected loadingController: LoadingController,
    private route: ActivatedRoute,
    protected platform: Platform,
  ) {
    super(loadingController, alertCtrl, null, null);
  }

  abaClick($event) {
    if ($event) {
      if ($event.detail.value == 'dados') {
      } else if ($event.detail.value == 'insights') {
        this.carregarVotosPartido();
        this.carregarVotosEstado();
        this.carregarVotosGenero();
        this.carregarGovernismo();
      }
      this.aba = $event.detail.value;
    }
  }

  ionViewWillEnter() {
    this.aba = 'dados';
  }


  carregarGovernismo() {
    this.sessaoPlenarioService
      .governismo(this.projetoSessaoPlenario.Votacao.Id)
      .then((data) => {
        var width = (this.platform.width() as any) - ((this.platform.width() as any * 0.1));
        var chartWidth = (this.platform.width() as any) - ((this.platform.width() as any * 0.3));
        this.governismoLista = (data as any[]).map((x) => [
          x.Bancada.toString(),
          x.QtdSeguiu as number,
          x.QtdNaoSeguiu as number,
        ]);
        this.governismoLista.unshift(['Orientação da bancada', 'Seguiu', 'Não Seguiu']);
        this.columnChart4 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.governismoLista,
          options: {
            width,
            chartArea: { width: chartWidth },
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            title: 'Orientação da bancada',
            bar: { groupWidth: '100%' },
            isStacked: true,
            legend: {
              position: 'top',
            },
          },
        };
      });
  }

  carregarVotosPartido() {
    this.sessaoPlenarioService
      .listarVotoPartidoGrafico({
        IdSessao: this.projetoSessaoPlenario.Votacao.Id,
      })
      .then((data) => {
        var width = (this.platform.width() as any) - ((this.platform.width() as any * 0.1));
        var chartWidth = (this.platform.width() as any) - ((this.platform.width() as any * 0.3));
        this.votoPartidoLista = (data as any[]).map((x) => [
          x.Partido.toString(),
          x.VotoSim as number,
          x.VotoNao as number,
        ]);
        this.votoPartidoLista.unshift(['Partido', 'Sim', 'Não']);
        this.columnChart1 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.votoPartidoLista,
          options: {
            // chartArea: { width: chartWidth, height: '200' },
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            title: 'Votos por partido',
            bar: { groupWidth: '100%' },
            isStacked: true,
            height: 400,
            width: width,
            legend: {
              position: 'top',
            },
          },
        };
      });
  }
  carregarVotosEstado() {
    this.sessaoPlenarioService
      .listarVotoEstadoGrafico({
        IdSessao: this.projetoSessaoPlenario.Votacao.Id,
      })
      .then((data) => {
        var width = (this.platform.width() as any) - ((this.platform.width() as any * 0.1));
        var chartWidth = (this.platform.width() as any) - ((this.platform.width() as any * 0.3));
        this.votoPartidoLista = (data as any[]).map((x) => [
          x.Partido.toString(),
          x.VotoSim as number,
          x.VotoNao as number,
        ]);
        this.votoPartidoLista.unshift(['Estado', 'Sim', 'Não']);
        this.columnChart2 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.votoPartidoLista,
          options: {
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            title: 'Votos por estado',
            bar: { groupWidth: '100%' },
            isStacked: true,
            height: 500,
            width: width,
            legend: {
              position: 'top',
            },
          },
        };
      });
  }

  carregarVotosGenero() {
    this.sessaoPlenarioService
      .listarVotoGeneroGrafico({
        IdSessao: this.projetoSessaoPlenario.Votacao.Id,
      })
      .then((data) => {
        var width = (this.platform.width() as any) - ((this.platform.width() as any * 0.1));
        var chartWidth = (this.platform.width() as any) - ((this.platform.width() as any * 0.3));
        this.votoPartidoLista = (data as any[]).map((x) => [
          x.Partido.toString(),
          x.VotoSim as number,
          x.VotoNao as number,
        ]);
        this.votoPartidoLista.unshift(['Gênero', 'Sim', 'Não']);
        this.columnChart3 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.votoPartidoLista,
          options: {
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            title: 'Votos por Gênero',
            bar: { groupWidth: '100%' },
            isStacked: true,
            height: 200,
            width: width,
            legend: {
              position: 'top',
            },
          },
        };
      });
  }

  compareWith(o1: any, o2: any) {
    if (o2 === true) return true;
    return o1 === o2;
  }

  ngOnInit() {
    var id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.nativePlatform = false;
      this.sessaoPlenarioService
        .listarPorId(id).then(data => {
          this.projetoSessaoPlenario = data;
          this.carregar()
        });
    }
    else {
      this.carregar();
    }
  }
  carregar() {
    this.sessaoPlenarioService
      .obter(this.projetoSessaoPlenario.Votacao.Id)
      .then((listaVoto) => {
        this.listaVoto = listaVoto;
        this.partidoLista = this.listaVoto
          .map((x) => x.Partido)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
        this.partidoLista.unshift('Todos os partidos');
        this.listaVotoBkp = listaVoto;
        this.ufListaBkp = this.listaVoto
          .map((x) => x.UF)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
        this.ufLista = this.ufListaBkp;
        this.ufLista.unshift('Todos os estados');

        this.listaVotoTodos = listaVoto;
        this.VotoSim = this.listaVoto.filter((x) => x.Voto === 'SIM').length;
        this.VotoNao = this.listaVoto.filter((x) => x.Voto === 'NÃO').length;
        this.VotoAbstencao = this.listaVoto.filter(
          (x) => x.Voto === 'ABSTENÇÃO'
        ).length;
        this.VotoOutro = this.listaVoto.filter(
          (x) => x.Voto !== 'SIM' && x.Voto !== 'NÃO' && x.Voto !== 'ABSTENÇÃO'
        ).length;
      });
  }
  partidoChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== true
    ) {
      var lista =
        this.filtroUf.toString() != 'Todos os estados' && this.filtroUf !== true
          ? this.listaVotoBkp.filter((x) => x.UF == this.filtroUf.toString())
          : this.listaVotoBkp;
      if ($event.detail.value != 'Todos os partidos') {
        this.listaVoto = lista.filter((x) => x.Partido == $event.detail.value);
      } else {
        this.listaVoto = lista;
      }
    }
  }

  ufChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== true
    ) {
      var lista =
        this.filtroPartido.toString() != 'Todos os partidos' &&
          this.filtroPartido !== true
          ? this.listaVotoBkp.filter(
            (x) => x.Partido == this.filtroPartido.toString()
          )
          : this.listaVotoBkp;
      if ($event.detail.value != 'Todos os estados') {
        this.listaVoto = lista.filter((x) => x.UF == $event.detail.value);
      } else {
        this.listaVoto = lista;
      }
    }
  }
  verProjeto(idProjeto) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: idProjeto, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  share(destino) {
    var texto = `Dá uma olhada na votação do ${this.projetoSessaoPlenario.Titulo +
      ' (' +
      this.projetoSessaoPlenario.CategoriaStr +
      ')\r\n\r\n'
      } ${this.projetoSessaoPlenario.Ementa.trimStart()}\r\n\r\nClique pra visualizar detalhes sobre a votação`;
    var link = `https://legislapp.com.br/app/votacaoparlamentar/Index?Id=${this.projetoSessaoPlenario.Id}`;
    Share.share({
      title: 'Dá uma olhada nessa publicação',
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe com seus amigos e acumule pontos',
    })
      .then((arg) => { })
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }

  fechar() {
    this.modalController.dismiss({});
  }

  limparFiltroVoto() {
    this.filtroTipoVoto = undefined;
    this.listaVoto = this.listaVotoTodos;
    this.filtroPartido = true;
    this.filtroUf = true;
  }

  filtroVotoClick(tipoVoto) {
    this.filtroTipoVoto = tipoVoto;
    var listaVotoTodos = this.listaVotoTodos;
    listaVotoTodos =
      (this.filtroUf.toString() != 'Todos os estados' && this.filtroUf != true) ||
        (this.filtroPartido.toString() != 'Todos os partidos' && this.filtroPartido != true)
        ? this.listaVoto
        : this.listaVotoTodos;
    if (tipoVoto == 'OUTROS') {
      this.listaVoto = listaVotoTodos.filter(
        (x) => x.Voto !== 'SIM' && x.Voto !== 'NÃO' && x.Voto !== 'ABSTENÇÃO'
      );
    } else if (tipoVoto == 'ABS') {
      this.listaVoto = listaVotoTodos.filter((x) => x.Voto === 'ABSTENÇÃO');
    } else this.listaVoto = listaVotoTodos.filter((x) => x.Voto === tipoVoto);
  }

  onImgError(item) {
    item.Foto = '/assets/imagem-perfil.jpg';
  }

  async parlamentarClick(parlamentar) {
    this.exibirLoading('Carregando...');
    this.parlamentarService
      .obter({ Id: parlamentar.IdParlamentar })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ParlamentarComponent,
          cssClass: 'modal-pontuacao',
          componentProps: {
            parlamentar: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async criarRadar() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Clique em OK para salvar o radar',
      inputs: [
        {
          name: 'nomeProjeto',
          type: 'text',
          placeholder: 'Ex: PL 9999/2021',
          value: this.projetoSessaoPlenario.Titulo,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.exibirLoading('Carregando...');
            this.radarMonitoramentoService
              .cadastrar({
                IdUsuario: this.usuarioService.get().Id,
                Autor: '',
                Tags: data.nomeProjeto,
                Camara: true,
                Senado: true,
                Titulo: data.nomeProjeto,
              })
              .then((data) => {
                this.ocultarLoading();
                this.radarCadastrado = true;
              });
          },
        },
      ],
    });
    alertComponent.present();
    this.ocultarLoading();
  }
}
