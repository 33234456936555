import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class ComissaoService extends ApiConfig {
  dicionarioData: Object;

  constructor(private http: HttpClient) {
    super();
  }
  listarDicionario() {
    try {
      if (this.dicionarioData)
        return new Promise(async (resolve) => {
          resolve(this.dicionarioData);
        });
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/Comissao/listarDicionario')
          .subscribe(data => {
            this.dicionarioData = data;
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }

  listar(filtro) {
    try {
      return new Promise(async (resolve) => {

        this.http.post('https://api.legislapp.com.br/Comissao/Listar',
          filtro)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }

  listarPorParlamentar(idParlamentar) {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/Comissao/ListarPorParlamentar?idParlamentar=' + idParlamentar)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }

  graficoMembroPartido(id) {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/Comissao/GraficoMembroPartido?id=' + id)
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }

  obter(filtro) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Comissao/Obter?id=' + filtro.Id)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
  listarHistoricoMembro(id) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Comissao/ListarHistoricoMembro?id=' + id)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
}

