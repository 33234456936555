import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { PageBaseService } from '../util/page-base.service';

@Component({
  selector: 'app-ordem-dia',
  templateUrl: './ordem-dia.page.html',
  styleUrls: ['./ordem-dia.page.scss'],
})
export class OrdemDiaPage extends PageBaseService implements OnInit {
  @Input()
  listaOrdemDia: any;
  constructor(
    private modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
  ) {

    super(loadingController, alertCtrl, null, null);

  }

  ngOnInit() {}

  ordemDiaClick(ordemDia) {
    window.open(ordemDia.Url,
      '_blank'
  );
  }
  fechar() {
    this.modalController.dismiss();
  }
}
