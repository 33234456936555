import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { AssinaturaService } from '../api/assinatura.service';
import { UsuarioService } from '../api/usuario.service';
import { PageBaseService } from '../util/page-base.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Component({
  selector: 'app-assinatura',
  templateUrl: './assinatura.component.html',
  styleUrls: ['./assinatura.component.scss'],
})
export class AssinaturaComponent extends PageBaseService implements OnInit {
  exibeAssinar: boolean;
  plano: unknown;

  constructor(
    private assinatura: AssinaturaService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    protected platform: Platform,
    protected router: Router,
    protected loadingCtrl: LoadingController,
    protected alertCtrl: AlertController,
    private vibration: Vibration
  ) {
    super(loadingCtrl, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.vibration.vibrate([2000, 1000, 2000]);
    var id = this.route.snapshot.paramMap.get('NomePlano');
    if (id) {
      this.assinatura.exibirSucessoAssinatura();
    }
    else if (this.usuarioService.get()) {
      this.exibirLoading('Carregando... 💎');
      var that = this;
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data) => {
          this.plano = data;
          if (!this.plano) {
            this.usuarioService.criarPedidoAssinatura(this.usuarioService.get().Id).then((data) => {
              setTimeout(() => {
                (window as any).carregarPagamento(data, this.usuarioService.get().Email);
                that.ocultarLoading();
              }, 1000);
            });
          }
          else
            that.ocultarLoading();
        });
    }
  }

  assinar() {
    this.assinatura.assinar();
  }

  exibeLoading() {
    this.exibirLoading('Criando sua assinatura Premium 💎');
    var that = this;
    setTimeout(() => {
      that.ocultarLoading();
    }, 5000);
  }

}
