import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class VotoParlamentarService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listar(filtro) {
    try {
      return new Promise(async (resolve) => {
        this.http.post(this.URL_API + 'VotacaoParlamentar/Listar',
          filtro)
          .subscribe(data => {
            resolve(data);
          }, error => {
            
            
          });
      });
    } catch (error) {
      alert(error);
    }
  } 
  

}

