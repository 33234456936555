// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twitterborder {
  width: 50%;
  margin-top: 3px;
  float: left;
  min-width: 300px;
  margin-left: 5%;
}

.table {
  display: table;
  height: 100%;
  width: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  margin-top: 3px;
  float: left;
  min-width: 600px;
  margin-left: 23%;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/home/home.page.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;ACCF;;ADEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;ACCF;;ADEA;EACE,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;ACCF","sourcesContent":[".twitterborder {\r\n  width: 50%;\r\n  margin-top: 3px;\r\n  float: left;\r\n  min-width: 300px;\r\n  margin-left: 5%;\r\n}\r\n\r\n.table {\r\n  display: table;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.table-cell {\r\n  display: table-cell;\r\n  vertical-align: middle;\r\n  margin-top: 3px;\r\n  float: left;\r\n  min-width: 600px;\r\n  margin-left: 23%;\r\n}",".twitterborder {\n  width: 50%;\n  margin-top: 3px;\n  float: left;\n  min-width: 300px;\n  margin-left: 5%;\n}\n\n.table {\n  display: table;\n  height: 100%;\n  width: 100%;\n}\n\n.table-cell {\n  display: table-cell;\n  vertical-align: middle;\n  margin-top: 3px;\n  float: left;\n  min-width: 600px;\n  margin-left: 23%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
