import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class OrdemDiaService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/OrdemDia/Obter?Id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }

  listarPorProjetoLista(model) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/OrdemDia/listarPorProjetoLista', model)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listar(filtro) {
    try {
      return new Promise(async (resolve) => {
        this.http.post('https://api.legislapp.com.br/OrdemDia/Listar', filtro).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
            
          }
        );
      });
    } catch (error) {
      alert(error);
    }
  }

  listarPorTag(tag, idRadar) {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/OrdemDia/ListarPorTag?idRadar=' + idRadar + '&tag=' + tag)
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
}
