import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
// import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listarProgresso(idUsuario) {
    try {
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/home/ListarProgresso?idUsuario=' + idUsuario)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }
  listar(idUsuario, idRadar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          // this.URL_API +
          'https://api.legislapp.com.br/Home/Listar?IdUsuario=' +
          idUsuario +
          '&IdRadar=' +
          idRadar
        )
        .subscribe(
          (data) => {
            localStorage.setItem(
              'DATA_LISTAR_HOME',
              new Date().toISOString().slice(0, 10).replace(/-/g, '')
            );
            localStorage.setItem(
              'FILTRO_LISTAR_HOME',
              idUsuario + '|' + idRadar
            );
            localStorage.setItem('RESULTADO_LISTAR_HOME' + idRadar, JSON.stringify(data));
            resolve(data);
          },
          (error) => {
            setTimeout(() => { this.listar(idUsuario, idRadar) }, 3000);
          }
        );
    });
  }
}
