import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar(idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Newsletter/Listar?idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  gerar(id, plano) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Newsletter/Gerar?id=' +
            id +
            '&plano=' +
            plano,
          httpOptions
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  gerarTabelaProjetoLeiJob(id, idUsuario) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Newsletter/GerarTabelaProjetoLeiJob?idRadar=' +
            id +
            '&idUsuario=' +
            idUsuario,
          httpOptions
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  obterStatusNewsletter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Newsletter/Status?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  obter(idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Newsletter/obter?idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  deletar(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Newsletter/deletar?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  criar(newsletter) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Newsletter/Criar', newsletter)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
}
