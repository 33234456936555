import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogService } from '../api/log.service';
import { UsuarioService } from '../api/usuario.service';
import { ClienteService } from '../api/cliente-service.service';
import { AssinaturaService } from '../api/assinatura.service';

@Component({
  selector: 'app-meus-logs',
  templateUrl: './meus-logs.page.html',
  styleUrls: ['./meus-logs.page.scss'],
})
export class MeusLogsPage implements OnInit {
  plano: any;
  logsLista: any = [];

  constructor(
    private modalController: ModalController,
    private logService: LogService,
    private usuarioService: UsuarioService,
    private assinaturaService: AssinaturaService
  ) {}
  ngOnInit() {
    this.carregarLista();
  }

  carregarLista() {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((data: any) => {
        this.plano = data;
        this.logService
          .listarPorPlano(this.plano.Id)
          .then((planoLista: any) => {
            this.logsLista = planoLista;
          });
      });
  }

  fechar() {
    this.modalController.dismiss({});
  }
}
