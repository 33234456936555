/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AgendaAgentePublicoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar(data) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/AgendaAgentePublico/Listar?data=' + data
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // navigator.app.exitApp();
          }
        );
    });
  }
  listarLocais() {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/AgendaAgentePublico/ListarLocais')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // navigator.app.exitApp();
          }
        );
    });
  }
  listarPorAutoridade(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/AgendaAgentePublico/ListarPorAutoridade?id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // navigator.app.exitApp();
          }
        );
    });
  }
}
