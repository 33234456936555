import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { BYPASS_AUTH } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentoProjetoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/DocumentoProjeto/Obter?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  listarPorUsuario(idUsuario, idProjeto) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/DocumentoProjeto/ListarPorUsuario?idUsuario=' +
            idUsuario +
            '&idProjeto=' +
            idProjeto
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  enviarDocumento(documento, idUsuario, idProjeto) {
    try {
      let headers = new HttpHeaders()
        .set('idProjeto', idProjeto)
        .set('idUsuario', idUsuario.toString()); // create header object
      // headers.set('idUsuario', idUsuario.toString()); // create header object
      return new Promise(async (resolve) => {
        this.http
          .post(
            'https://api.legislapp.com.br/DocumentoProjeto/Upload',
            documento,
            { headers: headers }
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  listarRecentesPorProjeto(idProjetoLista) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/DocumentoProjeto/ListarRecentesPorProjeto',
          idProjetoLista,
          { context: new HttpContext().set(BYPASS_AUTH, true) }
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }

  listarPorTag(tag, idRadar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/DocumentoProjeto/ListarPorTag?idRadar=' +
            idRadar +
            '&tag=' +
            tag,
          { context: new HttpContext().set(BYPASS_AUTH, true) }
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }
  downloadDocumentoClDf(url, body) {
    const httpOptionsPlain = {
      headers: new HttpHeaders({
        observe: 'response',
        responseType: 'arraybuffer',
      }),
    };
    return new Promise(async (resolve) => {
      this.http
        .post(url, body, { responseType: "blob" })        
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }
}
