import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssinaturaComponent } from './assinatura.component';
import { AssinaturaPageRoutingModule } from './assinatura-routing.module';



@NgModule({
  declarations: [AssinaturaComponent],
  imports: [
    CommonModule,
    AssinaturaPageRoutingModule
  ],
  exports:[AssinaturaComponent]
})
export class AssinaturaModule { }
