import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }
  public subject = new Subject<any>();
  public subjectBack = new Subject<any>();

  publish(data: any) {
    this.subject.next(data);
  }

  publishBack(data: any) {
    this.subjectBack.next(data);
  }
}
