import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class RadarDiarioMonitoramentoService extends ApiConfig {
  listarData = {};
  promiseLista: Promise<unknown>;
  constructor(private http: HttpClient) {
    super();
  }
  listar(idUsuario) {
    var radarChave = idUsuario;
    if (this.listarData[radarChave])
      return new Promise(async (resolve) => {
        console.log('from cache');
        resolve(JSON.parse(this.listarData[radarChave]));
      });
    if (!this.promiseLista) {
      this.promiseLista = new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/RadarMonitoramentoDiario/Listar?idUsuario=' + idUsuario
          )
          .subscribe(
            (data: any) => {
              this.listarData[radarChave] = JSON.stringify(data);
              var data2 = [...data];
              resolve(data2);
              this.promiseLista = undefined;
            },
            (error) => {}
          );
      });
    }
    return this.promiseLista;
  }

  deletar(id) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/RadarMonitoramentoDiario/Deletar?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  cadastrar(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/RadarMonitoramentoDiario/criar', radar)
        .subscribe(
          (data) => {
            this.listarData = {};
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  gerarWord(id, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/RadarMonitoramentoDiario/gerarWord?id=' +
            id +
            '&idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  gerarPDF(id, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/RadarMonitoramentoDiario/gerarPDF?id=' +
            id +
            '&idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  alterar(radar) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/RadarMonitoramentoDiario/alterar', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
}
