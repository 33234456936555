import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../api/cliente-service.service';
import { UsuarioService } from '../api/usuario.service';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { MeusClientesEdicaoPage } from '../meus-clientes-edicao/meus-clientes-edicao.page';
import { Subject } from 'rxjs';
import { MembroClienteEdicaoPage } from '../membro-cliente-edicao/membro-cliente-edicao.page';
import { AssinaturaService } from '../api/assinatura.service';

@Component({
  selector: 'app-meus-clientes',
  templateUrl: './meus-clientes.page.html',
  styleUrls: ['./meus-clientes.page.scss'],
})
export class MeusClientesPage implements OnInit {
  clienteLista: any = [];
  plano: unknown;
  constructor(
    private loadingController: LoadingController,
    private clienteService: ClienteService,
    private assinaturaService: AssinaturaService,
    private modalController: ModalController,
    private usuarioService: UsuarioService,
    public actionSheetController: ActionSheetController
  ) {}
  ngOnInit() {
    this.carregarLista();
  }

  deletarMembro(membro: any) {
    if (confirm('Deseja realmente remover o membro ' + membro.Nome + '?')) {
      this.clienteService.deletarMembro(membro).then((data: any) => {
        this.carregarLista();
      });
    }
  }
  async carregarLista() {
    const loading = await this.loadingController.create({
      message: 'Carregando...',
      spinner: 'dots',
      translucent: true
    });
    await loading.present();

    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((data) => {
        this.plano = data;
        if (!this.plano)
          this.assinaturaService.inicializar(this.usuarioService.get());
      })
      .finally(() => {
        loading.dismiss();
      });

    this.clienteService
      .listarPorUsuarioAdmin(this.usuarioService.get().Id)
      .then((data: any) => {
        this.clienteLista = data;
      })
      .finally(() => {
        loading.dismiss();
      });
  }
  async alterarMembro(cliente: any, membro: any) {
    var subject = new Subject<any>();
    subject.subscribe((data: any) => {
      this.carregarLista();
    });
    const modal = await this.modalController.create({
      component: MembroClienteEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        cliente,
        objEdicao: membro,
        subject
      },
    });

    return await modal.present();
  }

  async clienteClick(cliente: any) {
    var subject = new Subject<any>();
    subject.subscribe((data: any) => {
      this.carregarLista();
    });
    const modal = await this.modalController.create({
      component: MembroClienteEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        cliente,
        subject
      },
    });

    return await modal.present();
  }
  async cadastrarClienteClick(cliente: any) {
    var subject = new Subject<any>();
    subject.subscribe((data: any) => {
      this.carregarLista();
    });
    const modal = await this.modalController.create({
      component: MeusClientesEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        objEdicao: cliente,
        subject
      },
    });

    return await modal.present();
  }

  async clienteDOUClick(cliente) {
    const actionSheet = await this.actionSheetController.create({
      header: cliente.Nome,
      cssClass: '',
      buttons: [
        {
          text: 'Adicionar Membros',
          icon: 'person-add-outline',
          handler: async () => {
            this.clienteClick(cliente);
          },
        },
        {
          text: 'Editar Cliente',
          icon: 'pencil',
          handler: () => {
            this.cadastrarClienteClick(cliente);
          },
        },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: async () => {
            if (confirm('Deseja realmente remover o cliente ' + cliente.Nome + '?')) {
              const loading = await this.loadingController.create({
                message: 'Carregando...',
                spinner: 'dots',
                translucent: true
              });
              await loading.present();
              this.clienteService.deletarCliente(cliente.Id).then((data: any) => {
                this.carregarLista();
              }).finally(() => {
                loading.dismiss();
              });;
            }
            
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }

  fechar() {
    this.modalController.dismiss({});
  }
}
