import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioService } from './usuario.service';
// import { UsuarioService } from './usuario.service';
export const BYPASS_AUTH = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  token: any;

  constructor(private http: HttpClient, private usuarioService: UsuarioService) {
  }
  getToken() {
    return this.token;
  }
  loadToken() {
    return;
    this.http.post('https://api.legislapp.com.br/Usuario/Tkn', this.usuarioService.get(), { context: new HttpContext().set(BYPASS_AUTH, true) }).subscribe(
      (data: any) => {
        this.token = data.token;
        return this.token;
      },
      (error) => {
        console.log(error);
        this.token = 'data';
        return this.token;
        //alert(
        //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
        //);
      }
    );
  }
}
