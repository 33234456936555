import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class ParlamentarVisualizadoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  listar(login) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get('https://api.legislapp.com.br/ParlamentarVisualizadoUsuario/ListarPorUsuarioGet?usuario=' + login)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => { }
          );
      });
    } catch (error) {
      alert(error);
    }    
  }
  criar(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/ParlamentarVisualizadoUsuario/criar',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
}
