import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { BYPASS_AUTH } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class DiscursoService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listarRecente() {
    try {
      return new Promise(async (resolve) => {

        this.http.post('https://api.legislapp.com.br/Discurso/listarRecentes',
          {})
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
  listarPorProjeto(idProjetoLista) {
    try {
      return new Promise(async (resolve) => {

        this.http.post('https://api.legislapp.com.br/Discurso/ListarPorProjetoLei',
          idProjetoLista)
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Discurso/Obter?Id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarPorTag(tag) {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/Discurso/ListarPorTag2?tag=' + tag, { context: new HttpContext().set(BYPASS_AUTH, true) })
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
  listarPorAutor(tag) {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/Discurso/listarPorAutor?nome=' + tag, { context: new HttpContext().set(BYPASS_AUTH, true) })
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
}

