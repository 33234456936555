import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { IonicModule } from '@ionic/angular';
import { AutoridadeComponent } from './autoridade.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    Ng2GoogleChartsModule
  ],
  declarations: [AutoridadeComponent]
})
export class AutoridadePageModule {}
