// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hideInput {
  position: absolute;
  bottom: -100000000px;
}`, "",{"version":3,"sources":["webpack://./src/app/proposicao/proposicao.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/proposicao/proposicao.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oBAAA;ACCJ","sourcesContent":[".hideInput {\r\n    position: absolute;\r\n    bottom: -100000000px;\r\n}",".hideInput {\n  position: absolute;\n  bottom: -100000000px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
