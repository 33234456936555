import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(error: any): void {
    // alert(error);
    super.handleError(error);
  }
}