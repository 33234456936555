/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgendaSessaoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar(data) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/agendaSessao/Listar', data)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
  listarPorPeriodo(data) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/agendaSessao/ListarPorPeriodo', data)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
  exportar(data) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/agendaSessao/exportar', data)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
  listarPorComissao(idComissao, data) {
    return new Promise(async (resolve) => {
      this.http.get(`https://api.legislapp.com.br/agendaSessao/ListarPorComissao?id=${idComissao}&data=${data}`)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }

  listarProgressao(radar) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/agendaSessao/ListarProgresso?' + radar)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
  listarPorTag(tags) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/agendaSessao/listarPorTag?' + tags)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarPorProjetoLista(model) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/agendaSessao/listarPorProjetoLista', model)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarProgressaoPorProjetoLista(model) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/agendaSessao/ListarProgressoPorProjetoLista', model)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/agendaSessao/Obter?Id=' + id)
        .subscribe(data => {
          resolve(data);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
  obterTexto(data) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/agendaSessao/ObterTexto?Data=' + data)
        .subscribe(response => {
          resolve(response);
        }, error => {

          // navigator.app.exitApp();
        });
    });
  }
}
