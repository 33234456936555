import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class AutoridadeService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  listar(data) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Autoridade/Listar?data=' + data)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
}
