// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label,
strong {
  color: white;
}

.center {
  text-align: center;
}

ion-toolbar {
  --background: black;
  height: 100%;
}

ion-list {
  /* optional, but it needs when you use gradient as a background color.*/
  background: transparent;
}

.item-background-color {
  --ion-item-background: black;
  color: white;
}

.padding-top-ios {
  padding-top: 35px;
}

.padding-top-android {
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/painel-menu/painel-menu.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/painel-menu/painel-menu.page.scss"],"names":[],"mappings":"AAAA;;EAEI,YAAA;ACCJ;;ADEA;EACI,kBAAA;ACCJ;;ADGA;EACI,mBAAA;EACA,YAAA;ACAJ;;ADGA;EACI,uEAAA;EACA,uBAAA;ACAJ;;ADGA;EACI,4BAAA;EACA,YAAA;ACAJ;;ADGA;EACI,iBAAA;ACAJ;;ADGA;EACI,gBAAA;ACAJ","sourcesContent":["label,\r\nstrong {\r\n    color: white;\r\n}\r\n\r\n.center {\r\n    text-align: center;\r\n}\r\n\r\n\r\nion-toolbar {\r\n    --background: black;\r\n    height: 100%;\r\n}\r\n\r\nion-list {\r\n    /* optional, but it needs when you use gradient as a background color.*/\r\n    background: transparent;\r\n}\r\n\r\n.item-background-color {\r\n    --ion-item-background: black;\r\n    color: white;\r\n}\r\n\r\n.padding-top-ios {\r\n    padding-top: 35px;\r\n}\r\n\r\n.padding-top-android {\r\n    padding-top: 5px;\r\n}","label,\nstrong {\n  color: white;\n}\n\n.center {\n  text-align: center;\n}\n\nion-toolbar {\n  --background: black;\n  height: 100%;\n}\n\nion-list {\n  /* optional, but it needs when you use gradient as a background color.*/\n  background: transparent;\n}\n\n.item-background-color {\n  --ion-item-background: black;\n  color: white;\n}\n\n.padding-top-ios {\n  padding-top: 35px;\n}\n\n.padding-top-android {\n  padding-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
