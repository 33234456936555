import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class CampoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  listarParlamentar(idUsuario, idParlamentar) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Campo/ListarPorParlamentar?idUsuario=' + idUsuario + '&idParlamentar=' + idParlamentar)
        .subscribe(data => {
          resolve(data);
        }, error => {

        });
    });
  }
  listarProjetoLei(idUsuario, idProjetoLei) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Campo/ListarPorProjeto?idUsuario=' + idUsuario + '&idProjeto=' + idProjetoLei)
        .subscribe(data => {
          resolve(data);
        }, error => {

        });
    });
  }
  
  alterar(campo) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/Campo/Atualizar', campo)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }

  inserir(campo) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/Campo/Inserir', campo)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
}
