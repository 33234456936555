import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarefaEdicaoComponent } from './tarefa-edicao.component';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { QuillModule } from 'ngx-quill';


@NgModule({
  declarations: [TarefaEdicaoComponent],
  imports: [
    CommonModule, 
    TypeaheadPageModule,
    QuillModule.forRoot({
      modules: {
        syntax: true
      }
    }),
  ],
  exports:[
    TarefaEdicaoComponent
  ]
})
export class TarefaEdicaoModule { }
