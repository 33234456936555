import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { AgendaParlamentarComponentPage } from './agenda-parlamentar-component.page';

@NgModule({
  declarations: [AgendaParlamentarComponentPage],
  imports: [
    CommonModule,
    TypeaheadPageModule
  ],
  exports:[
    AgendaParlamentarComponentPage
  ]
})
export class AgendaParlamentarComponentPageModule { }
