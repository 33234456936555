import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoNotificacaoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  obter(idUsuario) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/ConfiguracaoNotificacao/Obter?idUsuario=' + idUsuario)
        .subscribe(data => {
          resolve(data);
        }, error => {
          
          
        });
    });
  }

  alterar(configuracaoPush) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/ConfiguracaoNotificacao/Alterar', configuracaoPush)
        .subscribe(data => {
          resolve(data);
        }, error => {
          
          
        });
    });
  }
}
