import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContadorProposicaoComponent } from './contador-proposicao.component';



@NgModule({
  declarations: [ContadorProposicaoComponent],
  imports: [
    CommonModule
  ],
  exports:[ContadorProposicaoComponent]
})
export class ContadorProposicaoModule { }
