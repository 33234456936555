import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { BYPASS_AUTH } from './interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class DiarioOficialService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listarProgresso(idUsuario) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/DiarioOficialUniao/ListarProgresso?idUsuario=' +
              idUsuario
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  listarProgressoTag(tags) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/DiarioOficialUniao/ListarProgressoTag?' +
              tags,
            { context: new HttpContext().set(BYPASS_AUTH, true) }
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  listarPorData(data, idUsuario) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/DiarioOficialUniao/Listar?data=' +
              data +
              '&idUsuario=' +
              idUsuario
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  listarPregoes(idUsuario, tagLista) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/DiarioOficialUniao/ListarPregoes?idUsuario=' +
              idUsuario +
              '&' +
              tagLista
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/DiarioOficialUniao/Obter?Id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  excel(id) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/DiarioOficialUniao/Excel?idRadar=' + id
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarPorTag(tag, radar) {
    try {
      if (!tag)
        return new Promise(async (resolve) => {
          resolve([]);
        });
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/DiarioOficialUniao/ListarPorTag?' +
              radar +
              '&tag=' +
              tag,
            { context: new HttpContext().set(BYPASS_AUTH, true) }
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(null);
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  listarSecao() {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get('https://api.legislapp.com.br/DiarioOficialUniao/listarSecao', {
            context: new HttpContext().set(BYPASS_AUTH, true),
          })
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(null);
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  listarOrgao() {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get('https://api.legislapp.com.br/DiarioOficialUniao/listarOrgao', {
            context: new HttpContext().set(BYPASS_AUTH, true),
          })
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(null);
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }
}
