import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class LogService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listarPorPlano(idPlano) {
    try {
      return new Promise(async (resolve) => {
        this.http.get(`https://api.legislapp.com.br/Log/Listar?idPlano=${idPlano}`)
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }

  
}

