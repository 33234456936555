import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GroupPipe, OrderByGroupedPipe, SafePipe } from './pipes.pipe';

@NgModule({
    declarations: [GroupPipe, OrderByGroupedPipe, SafePipe],
    imports: [IonicModule],
    exports: [GroupPipe, OrderByGroupedPipe, SafePipe]
})
export class PipesModule { }
