import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class RadarMonitoramentoService extends ApiConfig {
  preVisualizarData = {};
  listarData = {};
  tagsProposicaoData: Object;
  promiseListarTagsProposicao: any;
  promiseLista: Promise<unknown>;
  constructor(private http: HttpClient) {
    super();
  }

  listar(idUsuario) {
    var radarChave = idUsuario;
    if (this.listarData[radarChave])
      return new Promise(async (resolve) => {
        console.log('from cache');
        resolve(JSON.parse(this.listarData[radarChave]));
      });
    if (!this.promiseLista) {
      this.promiseLista = new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/Radar/Listar?idUsuario=' + idUsuario
          )
          .subscribe(
            (data: any) => {
              this.listarData[radarChave] = JSON.stringify(data);
              var data2 = [...data];
              resolve(data2);
              this.promiseLista = undefined;
            },
            (error) => {}
          );
      });
    }
    return this.promiseLista;
  }

  listarTagsProposicao() {
    if (this.tagsProposicaoData)
      return new Promise(async (resolve) => {
        resolve(this.tagsProposicaoData);
      });

    this.promiseListarTagsProposicao = new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Radar/listarTagsProposicao')
        .subscribe(
          (data) => {
            this.tagsProposicaoData = data;
            resolve(data);
            this.promiseListarTagsProposicao = undefined;
          },
          (error) => {}
        );
    });
    return this.promiseListarTagsProposicao;
  }

  listarTagsParlamentar() {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Radar/ListarTagsParlamentar')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  deletar(id) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Radar/Deletar?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarStakeholders(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/ListarStakeholders2', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  limparCache() {
    this.listarData = {};
  }

  cadastrar(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/criar', radar)
        .subscribe(
          (data) => {
            this.Indexar(data);
            this.listarData = {};
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  ingressar(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/ingressar', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  criarLink(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/criarLink', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  tendencia() {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Radar/Tendencia').subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  insights(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/InsightsPorRadar', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  insightsPorProjeto(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/InsightsPorProjeto', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  listarProgressaoMovimentacao(radar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/ListarProgressaoMovimentacao?' +
            radar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  ListarProgressaoMovimentacaoPorProjeto(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/ListarProgressaoMovimentacaoPorProjeto',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoDocumentoPorProjeto(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/listarProgressaoDocumentoPorProjeto',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoDocumento(radar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/ListarProgressaoDocumento?' +
            radar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  listarProgressaoTwitter(radar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/listarProgressaoTwitter?' + radar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoNoticia(radar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/ListarProgressaoNoticias?' + radar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoTwitterPorProjeto(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/listarProgressaoTwitterPorProjeto',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoNoticiaPorProjeto(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/ListarProgressaoNoticiasPorProjeto',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoNoticiaRadarProjeto(projetoLista) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/listarProgressaoNoticiaRadarProjeto',
          projetoLista
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoDocumentoRadarProjeto(projetoLista) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/ListarProgressaoDocumentoRadarProjeto',
          projetoLista
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  listarProgressaoMovimentacaoRadarProjeto(projetoLista) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Radar/ListarProgressaoMovimentacaoRadarProjeto',
          projetoLista
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  alterar(radar) {
    this.listarData = {};
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/alterar', radar)
        .subscribe(
          (data) => {
            this.Indexar(radar.Id);
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  previsualizarIndex(radar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/PrevisualizarIndex', radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  gerarPDF(id, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/gerarPDF?id=' +
            id +
            '&idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  gerarWord(id, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/gerarWord?id=' +
            id +
            '&idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  getGerarWordListaUrl(idLista, idUsuario) {
    var query = new URLSearchParams();
    idLista.forEach((element) => {
      query.append('idLista', element);
    });
    return (
      'https://api.legislapp.com.br/Radar/gerarWordLista?idUsuario=' +
      idUsuario +
      '&' +
      query.toString()
    );
  }
  gerarWordLista(idLista, idUsuario) {
    return new Promise(async (resolve) => {
      this.http.get(this.getGerarWordListaUrl(idLista, idUsuario)).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {}
      );
    });
  }
  async preVisualizar(radar) {
    var radarStr = JSON.stringify(radar);
    var radarChave = btoa(radarStr);
    if (this.preVisualizarData[radarChave])
      return new Promise(async (resolve) => {
        console.log('from cache');
        resolve(JSON.parse(this.preVisualizarData[radarChave]));
      });
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Radar/Previsualizar', radar)
        .subscribe(
          (data) => {
            this.preVisualizarData[radarChave] = JSON.stringify(data);
            console.log('from server');
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  async excel(idRadar, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/excel?idUsuario=' +
            idUsuario +
            '&id=' +
            idRadar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  async InserirProjeto(idRadar, idProjeto, indexar = true) {
    this.preVisualizarData = {};
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/InserirProjeto?idProjeto=' +
            idProjeto +
            '&idRadar=' +
            idRadar
        )
        .subscribe(
          (data) => {
            if (indexar) this.Indexar(idRadar);
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  async Indexar(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Radar/Indexar?id=' + id)
        .subscribe(
          (data) => {
            resolve(data);
            this.limparCache();
            localStorage.setItem('RADAR_MODIFICADO', 'true');
          },
          (error) => {}
        );
    });
  }
  async ExcluirProjeto(idRadar, idProjeto) {
    this.preVisualizarData = {};

    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Radar/ExcluirProjeto?idProjeto=' +
            idProjeto +
            '&idRadar=' +
            idRadar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  async preVisualizarProjetoLei(radar) {
    var radarStr = JSON.stringify(radar);
    var radarChave = btoa(radarStr);
    if (this.preVisualizarData[radarChave])
      return new Promise(async (resolve) => {
        console.log('from cache');
        resolve(JSON.parse(this.preVisualizarData[radarChave]));
      });
    else {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/Radar/ListarProjetoLei?idRadar=' +
              radar.Id
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {}
          );
      });
    }
  }

  async preVisualizarProjetoLeiLista(radar) {
    var radarStr = JSON.stringify(radar);
    var radarChave = btoa(radarStr);
    if (this.preVisualizarData[radarChave])
      return new Promise(async (resolve) => {
        console.log('from cache');
        resolve(JSON.parse(this.preVisualizarData[radarChave]));
      });
    else {
      return new Promise(async (resolve) => {
        this.http
          .post(
            'https://api.legislapp.com.br/Radar/ListarProjetoLeiLista',
            radar
          )
          .subscribe(
            (data) => {
              this.preVisualizarData[radarChave] = JSON.stringify(data);
              resolve(data);
            },
            (error) => {}
          );
      });
    }
  }

  preVisualizarCount(radar) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Radar/PrevisualizarCount?' + radar)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
}
