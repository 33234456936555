/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AgendaParlamentarService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar(data, idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/AgendaParlamentar/Listar?data=' +
            data +
            '&idParlamentar=' +
            idParlamentar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // navigator.app.exitApp();
          }
        );
    });
  }
}
