import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { ClienteService } from '../api/cliente-service.service';
import { UsuarioService } from '../api/usuario.service';

@Component({
  selector: 'app-membro-cliente-edicao',
  templateUrl: './membro-cliente-edicao.page.html',
  styleUrls: ['./membro-cliente-edicao.page.scss'],
})
export class MembroClienteEdicaoPage implements OnInit {

  @Input()
  cliente: any;
  @Input()
  objEdicao: any = {};
  ionicForm: any;
  @Input()
  subject: Subject<any> = new Subject<any>();
  constructor(public formBuilder: FormBuilder, private toastController: ToastController, private modalController: ModalController, private clienteService: ClienteService, private usuarioService: UsuarioService) { }
  ngOnInit() {
    this.ionicForm = this.formBuilder.group({
      nome: [this.objEdicao.Nome, [Validators.required, Validators.minLength(5)]],
      email: [this.objEdicao.Email, [Validators.required, Validators.minLength(5)]]
    });
  }
  salvar() {
    if (this.ionicForm.valid) {
      var model = {
        Id: undefined,
        Nome: this.ionicForm.controls["nome"].value,
        Email: this.ionicForm.controls["email"].value,
        IdCliente: this.cliente.Id
      };
      var funcao;
      if (this.objEdicao?.Id) {
        model.Id = this.objEdicao.Id;
        funcao = this.clienteService.alterarMembro(model);
      }
      else
        funcao = this.clienteService.inserirMembro(model);
      funcao.then((data) => {
        this.presentToast('🎉 Cliente salvo com sucesso!');
        this.fechar();
        this.subject.next("");
      });
    }
  }
  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: '',
      duration: 10000,
      position: 'top',
      color: 'success',
      cssClass: '',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          text: ' ' + text
        },
      ],
    });
    toast.onDidDismiss().then((e) => { });
    toast.present();
  }
  deletarMembroPlano(membro: any) {

  }
  fechar() {
    this.modalController.dismiss({});
  }
}
