import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
// import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class ProjetoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar(filtro) {
    return new Promise(async (resolve) => {
      var ultimoFiltro = localStorage.getItem('FILTRO_LISTAR_PROJETO');
      var dataUltimoFiltro = localStorage.getItem('DATA_LISTAR_PROJETO');
      if (
        ultimoFiltro == JSON.stringify(filtro) &&
        dataUltimoFiltro ==
          new Date().toISOString().slice(0, 10).replace(/-/g, '')
      ) {
        resolve(JSON.parse(localStorage.getItem('RESULTADO_LISTAR_PROJETO')));
      } else {
        this.http
          .post('https://api.legislapp.com.br/ProjetoLei/Listar', filtro)
          .subscribe(
            (data) => {
              resolve(data);
              localStorage.setItem(
                'DATA_LISTAR_PROJETO',
                new Date().toISOString().slice(0, 10).replace(/-/g, '')
              );
              localStorage.setItem(
                'FILTRO_LISTAR_PROJETO',
                JSON.stringify(filtro)
              );
              localStorage.setItem(
                'RESULTADO_LISTAR_PROJETO',
                JSON.stringify(data)
              );
            },
            (error) => {
              // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      }
    });
  }
  visualizar(model) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/projetovisualizadousuario/criar',
          model
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarDocumentos(id) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/ProjetoLei/ListarDocumentos', id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  obterPctChanceAprovacao(id) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/ObterPctChanceAprovacao?idProjeto=' +
            id
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarBolha(idProjetoLista) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/ProjetoLei/listarBolha',
          idProjetoLista
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  bolhaRecente() {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/ProjetoLei/bolhaRecente')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarRadar(idProjeto, idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/ListarRadar?idProjeto=' +
            idProjeto +
            '&idUsuario=' +
            idUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarRelacionadas(idProjeto) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/Relacionados?IdProjeto=' +
            idProjeto
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  obter(filtro, error = null) {
    if (!error)
      error = (error) => {
        //alert(
        //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
        //);
      };
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/ProjetoLei/Obter', filtro)
        .subscribe((data) => {
          resolve(data);
        }, error);
    });
  }
  excel(model) {
    var query = new URLSearchParams();
    model.IdProjetoLista.forEach((element) => {
      query.append('id', element);
    });
    return (
      'https://api.legislapp.com.br/ProjetoLei/excel?nome=' +
      model.Nome +
      '&' +
      query.toString()
    );
  }
  listarPorTituloECasa(model) {
    var query = new URLSearchParams();
    model.tituloLista.forEach((element) => {
      query.append('tituloLista', element);
    });
    var url =
      'https://api.legislapp.com.br/ProjetoLei/ListarPorTituloECasa?idCategoria=' +
      model.IdCategoria +
      '&' +
      query.toString();
    return new Promise(async (resolve) => {
      this.http.get(url).subscribe((data) => {
        resolve(data);
      });
    });
  }

  pdf(model) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/pdf?idUsuario=' +
            model.IdUsuario +
            '&id=' +
            model.IdProjeto
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  termometro(idProjeto) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/Termometro?IdProjeto=' +
            idProjeto
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  count(idProjetoLista) {
    var query = new URLSearchParams();
    return new Promise(async (resolve) => {
      idProjetoLista.forEach((element) => {
        query.append('idProjetoLista', element);
      });
      this.http
        .get(
          'https://api.legislapp.com.br/ProjetoLei/count?' + query.toString()
        )
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  listarPloa() {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/ProjetoLei/listarPloa')
        .subscribe((data) => {
          resolve(data);
        });
    });
  }
}
