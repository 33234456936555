// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-primeiro-acesso {
  text-align: center;
  position: relative;
  background-color: black;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 250px;
}

.header-primeiro-acesso .logo-primeiro-acesso {
  width: 70%;
  top: 20%;
  position: relative;
  max-width: 300px;
}

.header-primeiro-acesso .bg {
  width: 100%;
}

p {
  text-align: center;
  color: #004997;
  font-family: sans-serif;
  font-size: 15px;
}

strong {
  font-family: sans-serif;
}

ion-toolbar {
  --background: white;
}`, "",{"version":3,"sources":["webpack://./src/app/primeiro-acesso/primeiro-acesso.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/primeiro-acesso/primeiro-acesso.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,4BAAA;EACA,0BAAA;EACA,aAAA;ACCJ;;ADCA;EACI,UAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;ACEJ;;ADCA;EACI,WAAA;ACEJ;;ADCA;EACI,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,eAAA;ACEJ;;ADAA;EACI,uBAAA;ACGJ;;ADAA;EACI,mBAAA;ACGJ","sourcesContent":[".header-primeiro-acesso {\r\n    text-align: center;\r\n    position: relative;\r\n    background-color: black;\r\n    background-repeat: no-repeat;\r\n    background-size: 100% auto;\r\n    height: 250px;\r\n}\r\n.header-primeiro-acesso .logo-primeiro-acesso {\r\n    width: 70%;\r\n    top: 20%;\r\n    position: relative;\r\n    max-width: 300px;\r\n}\r\n\r\n.header-primeiro-acesso .bg {\r\n    width: 100%;\r\n}\r\n\r\np {\r\n    text-align: center;\r\n    color: #004997;\r\n    font-family: sans-serif;\r\n    font-size: 15px;\r\n}\r\nstrong {\r\n    font-family: sans-serif;\r\n}\r\n\r\nion-toolbar {\r\n    --background: white;\r\n}\r\n",".header-primeiro-acesso {\n  text-align: center;\n  position: relative;\n  background-color: black;\n  background-repeat: no-repeat;\n  background-size: 100% auto;\n  height: 250px;\n}\n\n.header-primeiro-acesso .logo-primeiro-acesso {\n  width: 70%;\n  top: 20%;\n  position: relative;\n  max-width: 300px;\n}\n\n.header-primeiro-acesso .bg {\n  width: 100%;\n}\n\np {\n  text-align: center;\n  color: #004997;\n  font-family: sans-serif;\n  font-size: 15px;\n}\n\nstrong {\n  font-family: sans-serif;\n}\n\nion-toolbar {\n  --background: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
