import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class CongressoEuaService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }
  listarPorTag(tag) {
    try {
      let headers = new HttpHeaders().set('X-API-Key', 'qJPOHU4EFX93VLPw4U1fSBsw6lRhmYUfbxuzHYPw'); // create header object

      return new Promise(async (resolve) => {
        this.http.get('https://api.propublica.org/congress/v1/bills/search.json?query=' + tag, { headers: headers })
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }
}