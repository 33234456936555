// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section ion-icon {
  padding-left: 10px;
}

.google-chart {
  overflow-y: hidden;
  overflow-x: auto;
}

.twitterborder {
  width: 100%;
  height: 300px;
  margin-top: 3px;
  float: left;
  margin-left: 33%;
}

.table {
  display: table;
  height: 100%;
  width: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/parlamentar/parlamentar.component.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/parlamentar/parlamentar.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;ACCJ;;ADCA;EACI,kBAAA;EACA,gBAAA;ACEJ;;ADCA;EACI,WAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;ACEJ;;ADAE;EACE,cAAA;EACA,YAAA;EACA,WAAA;ACGJ;;ADDE;EACE,mBAAA;EACA,sBAAA;ACIJ","sourcesContent":["section ion-icon{\r\n    padding-left: 10px;\r\n}\r\n.google-chart {\r\n    overflow-y: hidden;\r\n    overflow-x: auto;    \r\n }\r\n \r\n.twitterborder {\r\n    width: 100%;\r\n    height: 300px;\r\n    margin-top: 3px;\r\n    float: left;\r\n    margin-left: 33%;\r\n  }\r\n  .table{\r\n    display:table;\r\n    height: 100%;\r\n    width: 100%;\r\n  }\r\n  .table-cell{\r\n    display:table-cell;\r\n    vertical-align: middle;\r\n  }","section ion-icon {\n  padding-left: 10px;\n}\n\n.google-chart {\n  overflow-y: hidden;\n  overflow-x: auto;\n}\n\n.twitterborder {\n  width: 100%;\n  height: 300px;\n  margin-top: 3px;\n  float: left;\n  margin-left: 33%;\n}\n\n.table {\n  display: table;\n  height: 100%;\n  width: 100%;\n}\n\n.table-cell {\n  display: table-cell;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
