import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class NotificacaoUsuarioService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listar(idUsuario) {
    try {
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/NotificacaoUsuario/ListarPorUsuario?idUsuario=' + idUsuario)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }


}

