// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding: 1rem;
  overflow: auto;
}

.table {
  width: 100%;
  border: 1px solid;
}
.table ion-grid {
  padding: 0;
}
.table ion-row {
  border: 1px solid;
}

@media (max-width: 764px) {
  ion-col {
    width: 78px;
    padding: 5;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/meus-logs/meus-logs.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/meus-logs/meus-logs.page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;ACCF;;ADEA;EACE,WAAA;EACA,iBAAA;ACCF;ADCE;EACE,UAAA;ACCJ;ADEE;EACE,iBAAA;ACAJ;;ADIA;EACE;IACM,WAAA;IACA,UAAA;ECDN;AACF","sourcesContent":[".container {\r\n  padding: 1rem;\r\n  overflow: auto;\r\n}\r\n\r\n.table {\r\n  width: 100%;\r\n  border: 1px solid;\r\n\r\n  ion-grid {\r\n    padding: 0;\r\n  }\r\n\r\n  ion-row {\r\n    border: 1px solid;\r\n  }\r\n}\r\n\r\n@media (max-width: 764px) {\r\n  ion-col {\r\n        width: 78px;\r\n        padding: 5;\r\n      }\r\n}\r\n\r\n\r\n\r\n",".container {\n  padding: 1rem;\n  overflow: auto;\n}\n\n.table {\n  width: 100%;\n  border: 1px solid;\n}\n.table ion-grid {\n  padding: 0;\n}\n.table ion-row {\n  border: 1px solid;\n}\n\n@media (max-width: 764px) {\n  ion-col {\n    width: 78px;\n    padding: 5;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
