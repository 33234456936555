import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsuarioService } from './usuario.service';
import { Platform, ToastController } from '@ionic/angular';
import {
  PushNotification,
  PushNotificationActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Capacitor } from '@capacitor/core';


@Injectable({
  providedIn: 'root',
})
export class PushService extends ApiConfig {
  email: any;
  constructor(
    private usuarioService: UsuarioService,
    public http: HttpClient,
    private router: Router,
    public toastController: ToastController,
    private platform: Platform,
    private oneSignal: OneSignal
  ) {
    super();
  }

  async presentToast(titulo, message, color) {
    const toast = await this.toastController.create({
      message,
      header: titulo,
      position: 'middle',
      duration: 3000,
      color,
    });
    toast.present();
  }

  onRegister(registrationId: any, http: HttpClient, resolve, that: any) {
    var so = that.platform.is('android') ? 'FCM' : 'APNS';
    console.log(registrationId);
    // alert(registrationId);

    localStorage.setItem('PUSH_TOKEN', registrationId);
    localStorage.setItem('SO', so);
    that.usuarioService
      .atualizarToken({
        Email: that.usuarioService.get().Email,
        Token: registrationId,
        SO: so,
      })
      .then((e) => {
        console.log(e);
        // alert(JSON.stringify(e));
      });
  }

  initializeIos(email) {
    // this.email = email;
    // var that = this;

    // try {

    //   this.oneSignal.promptForPushNotificationsWithUserResponse().then(x => {

    //   }).catch((ex) => { });
    //   var that = this;

    //   // Show us the notification payload if the app is open on our device
    //   PushNotifications.addListener('pushNotificationReceived',
    //     (notification: PushNotification) => {
    //       that.presentToast(notification.title, notification.subtitle, 'success');
    //     }
    //   );

    //   // Method called when tapping on a notification
    //   PushNotifications.addListener('pushNotificationActionPerformed',
    //     (notification: PushNotificationActionPerformed) => {
    //       if (notification.notification.data && notification.notification.data.Id && notification.notification.data.Tipo) {
    //         that.router.navigateByUrl('/tabs/home?Id=' + notification.notification.data.Id + '&Tipo=' + notification.notification.data.Tipo);
    //       }
    //     }
    //   );
    // } catch (error) {
    //   //alert(error);
    // }

  }

  initialize(email) {
    this.email = email;
    return new Promise(async (resolve) => {
      const that = this;
      PushNotifications.requestPermissions().then((res) => {
        // alert(res.receive);
        // if (Capacitor.getPlatform() === 'ios') {    // in this way, the token return doesn't work for Android!!! 
        //   FCM.getToken().then((r) => {
        //     alert(r.token);
        //   }).catch(e => alert(e));
        // }
        PushNotifications.addListener('registration', (registration) => {
          // alert('registration');
          that.onRegister(registration.value, that.http, resolve, that);
        });
        PushNotifications.addListener('registrationError', (registration) => {
          alert('erro push ' + registration);
        });
        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: any) => {
            that.presentToast(
              notification.title,
              notification.subtitle,
              'success'
            );
          }
        );
        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (notification) => {
            if (
              notification.notification.data &&
              notification.notification.data.Id &&
              notification.notification.data.Tipo
            ) {
              that.router.navigateByUrl(
                '/home?Id=' +
                notification.notification.data.Id +
                '&Tipo=' +
                notification.notification.data.Tipo
              );
            }
          }
        );
        PushNotifications.register();
      });
    });
  }
}
