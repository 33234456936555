import { Component, Input, OnInit } from '@angular/core';
import { Share } from '@capacitor/share';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ParlamentarService } from '../api/parlamentar.service';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { PageBaseService } from '../util/page-base.service';
import { ComissaoService } from '../api/comissao.service';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';

@Component({
  selector: 'app-comissao',
  templateUrl: './comissao.page.html',
  styleUrls: ['./comissao.page.scss'],
})
export class ComissaoPage extends PageBaseService implements OnInit {
  @Input()
  comissao: any;
  quantidadeHomens: any;
  quantidadeMulheres: any;
  segmento = 'dados';
  listaParlamentares: any[];
  membroPartidoLista: any[][];

  public columnChart1: GoogleChartInterface;

  constructor(
    public alertCtrl: AlertController,
    private modalController: ModalController,
    private parlamentarService: ParlamentarService,
    private comissaoService: ComissaoService,
    protected loadingController: LoadingController,
    protected platform: Platform
  ) {
    super(loadingController, alertCtrl, null, null);
  }
  segmentoChange($event) {
    if ($event) {
      this.segmento = $event.detail.value;
      if (this.segmento == 'historico')
        this.carregarListaHistorico();
      if (this.segmento == 'insights')
        this.carregarInsights();
    }
  }

  carregarInsights() {
    this.exibirLoading('Carregando...');
    this.comissaoService
      .graficoMembroPartido(this.comissao.Id)
      .then((data: any[]) => {
        this.membroPartidoLista = (data as any[]).map((x) => [
          x.Partido.toString(),
          x.Qtd as number,
        ]);
        var width = (this.platform.width() as any) - ((this.platform.width() as any * 0.1));
        var chartWidth = (this.platform.width() as any) - ((this.platform.width() as any * 0.4));
        this.membroPartidoLista.unshift(['Partido', 'Qtd de Membros por partido']);
        this.columnChart1 = {
          chartType: GoogleChartType.BarChart,
          dataTable: this.membroPartidoLista,
          options: {
            width,
            height: 300,
            chartArea: { width: chartWidth },
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 12, bold: true },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 12 } },
            bar: { groupWidth: '100%' },
            isStacked: true,
            legend: {
              position: 'top',
            },
          }
        };
        this.loading.dismiss();
      });
  }
  carregarListaHistorico() {
    this.exibirLoading('Carregando...');
    this.comissaoService
      .listarHistoricoMembro(this.comissao.Id)
      .then((historico: any[]) => {
        this.listaParlamentares = historico;
        this.loading.dismiss();
      });
  }
  ngOnInit() {
    this.comissao.MembroListaTitular = this.comissao.MembroLista.filter(
      (x) => x.Titular
    );
    this.comissao.MembroListaSuplente = this.comissao.MembroLista.filter(
      (x) => !x.Titular
    );
    this.comissao.MembroListaCargos = this.comissao.MembroLista.filter(
      (x) => x.Titulo
    );
    this.comissao.Listas = [
      this.comissao.MembroListaCargos,
      this.comissao.MembroListaTitular,
      this.comissao.MembroListaSuplente,
    ].filter((x) => x.length > 0);
    this.quantidadeHomens = this.comissao.MembroLista.filter(
      (x) => x.Sexo == 'M'
    ).length;
    this.quantidadeMulheres = this.comissao.MembroLista.filter(
      (x) => x.Sexo == 'F'
    ).length;

    this.comissao.MembroLista.map((item) => {
      this.parlamentarService
        .ObterNotaRanking({ idparlamentar: item.Id })
        .then((nota) => {
          item.NotaRankingRelevancia = nota;
        });
    });
  }

  fechar() {
    this.modalController.dismiss({});
  }

  onImgError(item) {
    item.Foto = '/assets/imagem-perfil.jpg';
  }

  async parlamentarClick(parlamentar) {
    this.exibirLoading('Carregando...');
    this.parlamentarService.obter({ Id: parlamentar.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ParlamentarComponent,
        cssClass: 'modal-pontuacao',
        componentProps: {
          parlamentar: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  share(destino) {
    var texto = `Dá uma olhada em ${this.comissao.Nome}`;
    var link = `https://legislapp.com.br/app/Comissao/Push/${this.comissao.Id}`;
    Share.share({
      title: 'Dá uma olhada nessa publicação',
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe com seus amigos e acumule pontos',
    })
      .then((arg) => { })
      .catch((error) => {
        alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }
}
