import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

 
  listarPorUsuarioAdmin(idUsuario) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Cliente/listarPorUsuarioAdmin?idUsuario=' + idUsuario)
        .subscribe(data => {
          resolve(data);
        }, error => {

        });
    });
  }
  
  alterar(Cliente) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/Cliente/Atualizar', Cliente)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
  alterarMembro(Cliente) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/MembroCliente/Atualizar', Cliente)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
  inserirMembro(membro) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/MembroCliente/Inserir', membro)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
  deletar(membro) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/Cliente/deletar', membro)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
  deletarMembro(membro) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/MembroCliente/deletar', membro)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
  inserir(Cliente) {
    return new Promise(async (resolve) => {
      this.http.post('https://api.legislapp.com.br/Cliente/Inserir', Cliente)
        .subscribe(data => {
          resolve(data);
        }, error => {


        });
    });
  }
  deletarCliente(Id) {
    return new Promise(async (resolve) => {
      this.http.delete('https://api.legislapp.com.br/Cliente/Deletar?id=' + Id)
        .subscribe(data => {
          resolve(data);
        }, error => {
        });
    });
  }
}
