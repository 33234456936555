import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
// import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class RetrospectivaService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  gerar(idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Home/ObterRetrospectiva?idUsuario=' + idUsuario)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }

  obterShareUrl(idUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .post(this.URL_API + 'Retrospectiva/obterShareUrl', { idUsuario })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
}
