import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  retryWhen,
  switchMap,
} from 'rxjs/operators';
import { TokenService } from './token.service';
import { UsuarioService } from './usuario.service';
export const BYPASS_AUTH = new HttpContextToken(() => false);
export const retryWaitMilliSeconds = 10000;
export const retryCount = 3;
@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    public auth: TokenService,
    private usuarioService: UsuarioService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_AUTH) === false) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`,
          IdUsuario: this.usuarioService.get()
            ? this.usuarioService.get().Id.toString()
            : '0',
        },
      });
      return next.handle(request).pipe(
        retryWhen((error) =>
          error.pipe(
            concatMap((error, count) => {
              if (count <= retryCount) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(retryWaitMilliSeconds)
          )
        )
      );
    } else {
      return next.handle(request);
    }
  }
}
