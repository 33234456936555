import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ementa',
  templateUrl: './ementa.component.html',
  styleUrls: ['./ementa.component.scss'],
})
export class EmentaComponent implements OnInit {
  @Input()
  ementa: any;
  constructor() { }

  ngOnInit() {}

}
