import { Component, OnInit } from '@angular/core';
import {
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { AgendaPage } from '../agenda/agenda.page';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { ComissaoService } from '../api/comissao.service';
import { DocumentoProjetoService } from '../api/documento-projeto.service';
import { NotificacaoUsuarioService } from '../api/notificacao-usuario.service';
import { ParlamentarService } from '../api/parlamentar.service';
import { ProjetoService } from '../api/projeto.service';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { UsuarioService } from '../api/usuario.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { VotoParlamentarPage } from '../voto-parlamentar/voto-parlamentar.page';
import { TwitterService } from '../api/twitter.service';
import { NoticiaService } from '../api/noticia.service';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { MovimentacaoService } from '../api/movimentacao.service';
import { OrdemDiaService } from '../api/ordem-dia.service';
@Component({
  selector: 'app-notificacao-usuario',
  templateUrl: './notificacao-usuario.page.html',
  styleUrls: ['./notificacao-usuario.page.scss'],
})
export class NotificacaoUsuarioPage extends PageBaseService implements OnInit {
  notificacaoLista: any[];

  constructor(
    private notificacaoUsuarioService: NotificacaoUsuarioService,
    private usuarioService: UsuarioService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected comissaoService: ComissaoService,
    protected agendaSessaoService: AgendaSessaoService,
    protected parlamentarService: ParlamentarService,
    protected projetoService: ProjetoService,
    protected documentoService: DocumentoProjetoService,
    protected sessaoPlenarioService: SessaoPlenarioService,
    protected twitterService: TwitterService,
    protected noticiaService: NoticiaService,
    protected diarioOficialService: DiarioOficialService,
    protected movimentacaoService: MovimentacaoService,
    protected ordemDiaService: OrdemDiaService,
    private modalController: ModalController
  ) {
    super(loadingController, alertCtrl, null, null);
  }

  ngOnInit() {
    this.carregarLista();
  }

  async parlamentarClick(rankingItem) {
    this.exibirLoading('Carregando...');
    this.parlamentarService.obter({ Id: rankingItem.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ParlamentarComponent,
        cssClass: 'modal-pontuacao',
        componentProps: {
          parlamentar: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  async agendaClick(id) {
    this.exibirLoading('Carregando...');
    this.agendaSessaoService.obter(id).then(async (agenda) => {
      const modal = await this.modalController.create({
        component: AgendaPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          agenda,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }
  comissaoClick(id) {
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }
  abrirDocumento(idDocumento) {
    this.exibirLoading('Carregando...');
    this.documentoService
      .obter(idDocumento)
      .then(async (dataDocumento: any) => {
        this.projetoService
          .obter({ Id: dataDocumento.IdProjeto })
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: ProposicaoPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                proposicao: data,
                aba: 'documentos',
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
      });
  }
  abrirTwitter(id) {
    this.exibirLoading('Carregando...');
    this.twitterService.obter(id).then((data: any) => {
      this.parlamentarService
        .obter({ Id: data.IdParlamentar })
        .then((parlamentar: any) => {
          var nomeUsuario = parlamentar.Twitter.split('/')
            .pop()
            .split('?')[0]
            .replace('@', '');
          var url =
            'https://twitter.com/' + nomeUsuario + '/status/' + data.IdTweet;
          window.open(url, '_system');
          this.ocultarLoading();
        });
    });
  }
  abrirNoticia(id) {
    this.exibirLoading('Carregando...');
    this.noticiaService.obter(id).then((data: any) => {
      window.open(data.Url, '_system');
      this.ocultarLoading();
    });
  }
  abrirDiarioOficial(id) {
    this.exibirLoading('Carregando...');
    this.diarioOficialService.obter(id).then((data: any) => {
      window.open(data.UrlDiario, '_system');
      this.ocultarLoading();
    });
  }

  abrirMovimentacao(id) {
    this.exibirLoading('Carregando...');
    this.movimentacaoService.obter(id).then((data: any) => {
      this.projetoService.obter({ Id: data.IdProjeto }).then((projeto: any) => {
        this.proposicaoClick(projeto, 'movimentacao');
        this.ocultarLoading();
      });
    });
  }

  abrirSessaoPlenario(id) {
    this.exibirLoading('Carregando...');
    this.sessaoPlenarioService.obterSessao(id).then((data: any) => {
      this.projetoService.obter({ Id: data.IdProjeto }).then((projeto: any) => {
        this.proposicaoClick(projeto, 'votacoes');
        this.ocultarLoading();
      });
    });
  }

  abrirOrdemDia(id) {
    this.exibirLoading('Carregando...');
    this.ordemDiaService.obter(id).then((data: any) => {
      window.open(data.Url, '_system');
      this.ocultarLoading();
    });
  }
  async proposicaoClick(proposicao, aba = 'dados') {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: proposicao.Id, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
            aba: aba,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async sessaoClick(projetoSessaoPlenario) {
    this.exibirLoading('Carregando...');
    const modal = await this.modalController.create({
      component: VotoParlamentarPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        projetoSessaoPlenario,
      },
    });
    this.ocultarLoading();
    return await modal.present();
  }

  tratarUrlNotificacao(urlFull) {
    var url = urlFull
      .split('?')[0]
      .split('https://legislapp.com.br')[1]
      .toLowerCase();
    var id = urlFull.split('=')[1];
    if (url == '/app/documentoprojeto/push') {
      this.abrirDocumento(id);
    } else if (url == '/app/projetolei/push') {
      this.proposicaoClick(id);
    } else if (url == '/app/parlamentar/visualizar') {
      this.parlamentarClick(id);
    } else if (url == '/app/agendasessao/push') {
      this.agendaClick(id);
    } else if (url == '/app/comissao/push') {
      this.comissaoClick(id);
    } else if (url == '/app/sessaoplenario/push') {
      this.exibirLoading('Carregando...');
      this.sessaoPlenarioService.obter(id).then((data) => {
        this.ocultarLoading();
        this.sessaoClick(data);
      });
    }
  }
  notificacaoClick(notificacao) {
    if (notificacao.NotificacaoReferenciaNavigation) {
      switch (notificacao.NotificacaoReferenciaNavigation[0].Tabela) {
        case 'Twitter':
          this.abrirTwitter(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'Newsletter':
          window.open(notificacao.UrlDestino, '_system');
          break;
        case 'DiarioOficial':
          this.abrirDiarioOficial(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'Noticia':
          this.abrirNoticia(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'Movimentacao':
          this.abrirMovimentacao(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'OrdemDia':
          this.abrirOrdemDia(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'DocumentoProjeto':
          this.abrirDocumento(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'SessaoPlenario':
          this.abrirSessaoPlenario(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'AgendaSessao':
          this.agendaClick(
            notificacao.NotificacaoReferenciaNavigation[0].IdTabela
          );
          break;
        case 'ProjetoLei':
          this.proposicaoClick({
            Id: notificacao.NotificacaoReferenciaNavigation[0].IdTabela,
          });
          break;
        default:
          break;
      }
    } else {
      // if (notificacao.UrlDestino.indexOf('storage') > -1)
      window.open(notificacao.UrlDestino, '_system');
      // this.tratarUrlNotificacao(notificacao.UrlDestino);
    }
    this.usuarioService.visualizarNotificacao(notificacao.Id);
  }
  fechar() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({});
  }

  onImgError(item) {
    item.UrlImagem = '/assets/imagem-perfil.jpg';
  }

  carregarLista() {
    this.exibirLoading('Carregando...');
    this.notificacaoUsuarioService
      .listar(this.usuarioService.get().Id)
      .then((data) => {
        this.notificacaoLista = data as any[];
        this.ocultarLoading();
      });
  }
}
