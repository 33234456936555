import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContadorProposicaoModule } from '../contador-proposicao/contador-proposicao.module';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { HomePage } from './home.page';
import { AgendaComponentPageModule } from '../agenda-component/agenda-component.module';

const routes: Routes = [
  {
    path: '',
    component: HomePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), IconeEmentaModule, ContadorProposicaoModule],
  exports: [RouterModule, IconeEmentaModule, ContadorProposicaoModule],
})
export class HomePageRoutingModule {}
