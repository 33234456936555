import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SessaoPlenarioService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listarVotoPartidoGrafico(idSessao) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .post(
            'https://api.legislapp.com.br/SessaoPlenario/ListarVotoPartidoGrafico',
            idSessao
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
                //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  governismo(idSessao) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get(
            'https://api.legislapp.com.br/SessaoPlenario/Governismo?idSessaoPlenaria=' + idSessao,
            idSessao
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
                //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  listarVotoEstadoGrafico(idSessao) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .post(
            'https://api.legislapp.com.br/SessaoPlenario/listarVotoEstadoGrafico',
            idSessao
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
                //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  listarVotoGeneroGrafico(idSessao) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .post(
           'https://api.legislapp.com.br/SessaoPlenario/listarVotoGeneroGrafico',
            idSessao
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
                //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }
  listar(filtro) {
    return new Promise(async (resolve) => {
      // var ultimoFiltro = localStorage.getItem('FILTRO_LISTAR_SESSAOPLENARIO');
      // var dataUltimoFiltro = localStorage.getItem('DATA_LISTAR_SESSAOPLENARIO');
      // if (
      //   ultimoFiltro == JSON.stringify(filtro) &&
      //   dataUltimoFiltro ==
      //     new Date().toISOString().slice(0, 10).replace(/-/g, '')
      // ) {
      //   resolve(
      //     JSON.parse(localStorage.getItem('RESULTADO_LISTAR_SESSAOPLENARIO'))
      //   );
      // }
      // this.http;
      this.http
        .post('https://api.legislapp.com.br/SessaoPlenario/Listar', filtro)
        .subscribe(
          (data) => {
            localStorage.setItem(
              'DATA_LISTAR_SESSAOPLENARIO',
              new Date().toISOString().slice(0, 10).replace(/-/g, '')
            );
            localStorage.setItem(
              'FILTRO_LISTAR_SESSAOPLENARIO',
              JSON.stringify(filtro)
            );
            localStorage.setItem(
              'RESULTADO_LISTAR_SESSAOPLENARIO',
              JSON.stringify(data)
            );
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  listarPorParlamentar(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/SessaoPlenario/ListarPorParlamentar', {
          idParlamentar,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarPorProjetoLista(idProjeto:any[]) {
    return new Promise(async (resolve) => {
      this.http
        .post(
            'https://api.legislapp.com.br/SessaoPlenario/ListarPorProjetoLista', idProjeto
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarProgressoAprovada(idProjeto:any[]) {
    return new Promise(async (resolve) => {
      this.http
        .post(
            'https://api.legislapp.com.br/SessaoPlenario/ListarProgressoAprovada', idProjeto
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarPorProjeto(idProjeto) {
    return new Promise(async (resolve) => {
      this.http
        .get(
            'https://api.legislapp.com.br/sessaoPlenario/ListarPorProjeto?idProjeto=' +
            idProjeto
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  listarPorId(id) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/SessaoPlenario/listarPorId?idSessao=' + id).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
          //);
        }
      );
    });
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/SessaoPlenario/Obter?id=' + id).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
          //);
        }
      );
    });
  }
  obterSessao(id) {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/SessaoPlenario/ObterSessaoPlenario?id=' + id).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
          //);
        }
      );
    });
  }
}
