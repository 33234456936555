// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip-pesquisa-selecionado {
  background-color: #148b7f;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/agenda-parlamentar-component/agenda-parlamentar-component.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/agenda-parlamentar-component/agenda-parlamentar-component.page.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;ACCJ","sourcesContent":[".chip-pesquisa-selecionado {\r\n    background-color: #148b7f;\r\n    color: white;\r\n  }",".chip-pesquisa-selecionado {\n  background-color: #148b7f;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
