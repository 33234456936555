import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { AgendaExecutivoComponentPage } from './agenda-executivo-component.page';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AgendaExecutivoComponentPage],
  imports: [
    CommonModule,
    TypeaheadPageModule,
    FormsModule
  ],
  exports:[
    AgendaExecutivoComponentPage
  ]
})
export class AgendaExecutivoComponentPageModule { }
