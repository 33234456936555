import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class MovimentacaoService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listarPorProjeto(idProjeto) {
    try {
      return new Promise(async (resolve) => {
        this.http.post('https://api.legislapp.com.br/movimentacao/ListarPorProjeto',
          { idProjeto })
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }

  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/movimentacao/Obter?Id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }

  ListarProgresso(idMateria) {
    try {
      return new Promise(async (resolve) => {
        this.http.post('https://api.legislapp.com.br/movimentacao/ListarProgresso',
          { idMateria })
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }

  listarTempoOrgao(idProjeto) {
    try {
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/movimentacao/ListarTempoOrgao?idProjeto=' + idProjeto)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }
  ListarTempoOrgaoListaProjeto(idProjetoLista) {
    try {
      return new Promise(async (resolve) => {
        this.http.post('https://api.legislapp.com.br/movimentacao/ListarTempoOrgaoListaProjeto', idProjetoLista)
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }

  listarProgressoSenado() {
    try {
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/movimentacao/ListarProgressoSenado')
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
  listarProgressoCamara() {
    try {
      return new Promise(async (resolve) => {
        this.http.get('https://api.legislapp.com.br/movimentacao/ListarProgressoCamara')
          .subscribe(data => {
            resolve(data);
          }, error => {


          });
      });
    } catch (error) {
      alert(error);
    }
  }
}

