// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section ion-icon {
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/autoridade/autoridade.component.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/autoridade/autoridade.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;ACCJ","sourcesContent":["section ion-icon{\r\n    padding-left: 10px;\r\n}","section ion-icon {\n  padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
