import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { MeuPlanoPage } from '../meu-plano/meu-plano.page';

@Injectable({
  providedIn: 'root',
})
export class AssinaturaService extends ApiConfig {
  IdUsuario: any;
  constructor(
    private http: HttpClient,
    private alertController: AlertController,
    private modalController: ModalController,
    private platform: Platform,
    private usuarioService: UsuarioService
  ) {
    super();
  }
  private jaPassou = localStorage.getItem('PLANO_ASSINADO');
  private assinaturaSubject = new Subject<any>();
  async exibirSucessoAssinatura() {
    const alert = await this.alertController.create({
      header: 'Sua assinatura foi criada com sucesso!',
      message:
        '<div class="modal-pontuacao-body"><div class="">Agora você pode usufruir dos nossos recursos premium como monitoramento e notificações em tempo real ilimitadas, newsletters automatizadas e insights exclusivos.</div></div></div>',
      buttons: [
        {
          text: 'Fechar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            window.location.href = '/';
          },
        },
      ],
    });
    await alert.present();
  }

  inicializar(IdUsuario) {
    this.IdUsuario = IdUsuario;
    this.usuarioService.obterPlano(IdUsuario).then((data) => {
      if (!data) {
        this.assinaturaSubject.next(false);
      } else this.assinaturaSubject.next(true);
    });
    return this.assinaturaSubject;
  }
  async assinar() {
    if(!this.platform.is('ios')){
      this.usuarioService.obterPlano(this.IdUsuario).then(async (data) => {
        if (!data) {
          const modal = await this.modalController.create({
            component: MeuPlanoPage,
            cssClass: 'modal-pontuacao',
          });
          await modal.present();
        } else this.assinaturaSubject.next(true);
      });
    }    
  }
}
