import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiarioOficialUsuarioComponent } from './diario-oficial-usuario.component';


@NgModule({
  declarations: [DiarioOficialUsuarioComponent],
  imports: [
    CommonModule
  ],
  exports:[
    DiarioOficialUsuarioComponent
  ]
})
export class DiarioOficialUsuarioModule { }
