import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { BYPASS_AUTH } from './interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class ParlamentarService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  listar(filtro) {
    try {
      return new Promise(async (resolve) => {
        var ultimoFiltro = localStorage.getItem('FILTRO_LISTAR_PARLAMENTAR');
        var dataUltimoFiltro = localStorage.getItem('DATA_LISTAR_PARLAMENTAR');
        if (
          ultimoFiltro == JSON.stringify(filtro) &&
          dataUltimoFiltro ==
            new Date().toISOString().slice(0, 10).replace(/-/g, '')
        ) {
          resolve(
            JSON.parse(localStorage.getItem('RESULTADO_LISTAR_PARLAMENTAR'))
          );
        }
        this.http
          .post('https://api.legislapp.com.br/Parlamentar/Listar', filtro)
          .subscribe(
            (data) => {
              localStorage.setItem(
                'DATA_LISTAR_PARLAMENTAR',
                new Date().toISOString().slice(0, 10).replace(/-/g, '')
              );
              localStorage.setItem(
                'FILTRO_LISTAR_PARLAMENTAR',
                JSON.stringify(filtro)
              );
              localStorage.setItem(
                'RESULTADO_LISTAR_PARLAMENTAR',
                JSON.stringify(data)
              );
              resolve(data);
            },
            (error) => {
              // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  rankingRelevancia(filtro) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .post(
            'https://api.legislapp.com.br/Parlamentar/RankingRelevancia',
            filtro
          )
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
              //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      });
    } catch (error) {
      alert(error);
    }
  }

  obter(filtro) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Parlamentar/Obter', filtro)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  quantidadeHomem() {
    return new Promise(async (resolve) => {
      this.http.get(this.URL_API + 'Parlamentar/quantidadeHomem').subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          //alert(
          //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
          //);
        }
      );
    });
  }

  obterPorNome(nome) {
    return new Promise(async (resolve) => {
      this.http
        .get(this.URL_API + 'Parlamentar/ObterPorNome?nome=' + nome)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  obterFidelidadePartidaria(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          this.URL_API +
            'Parlamentar/FidelidadePartidaria?idParlamentar=' +
            idParlamentar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  quantidadeMulher() {
    return new Promise(async (resolve) => {
      this.http.get(this.URL_API + 'Parlamentar/quantidadeMulher').subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          //alert(
          //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
          //);
        }
      );
    });
  }

  listarTendencia(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Parlamentar/Tendencia', {
          id: idParlamentar,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarMembroGabinete(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/parlamentar/ListarMembroGabinete',
          { idParlamentar }
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarTweets(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/parlamentar/ListarTweets', {
          idParlamentar,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  listarMencaoTwitter(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/parlamentar/ListarMencaoTwitter?idParlamentar=' +
            idParlamentar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  listarProximidade(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/parlamentar/ListarProximidade?idParlamentar=' +
            idParlamentar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  listarEnvolvimento(idParlamentar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/parlamentar/listarEnvolvimento?idParlamentar=' +
            idParlamentar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
  obterTwitterHtml(twitter) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/parlamentar/ObterTwitterHtmlGet?usuario=' +
            twitter.Usuario +
            '&idTweet=' +
            twitter.IdTweet
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }

  ObterNotaRanking(filtro) {
    return new Promise(async (resolve) => {
      if (localStorage.getItem('nota_' + filtro.idparlamentar))
        resolve(
          parseFloat(localStorage.getItem('nota_' + filtro.idparlamentar))
        );
      else {
        this.http
          .post(
            'https://api.legislapp.com.br/Parlamentar/ObterNotaRanking',
            filtro,
            { context: new HttpContext().set(BYPASS_AUTH, true) }
          )
          .subscribe(
            (data) => {
              localStorage.setItem(
                'nota_' + filtro.idparlamentar,
                data.toString()
              );
              resolve(data);
            },
            (error) => {
              resolve(0);
              // alert(
              //   //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      }
    });
  }
  listarProgressaoProposicao(filtro) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Parlamentar/ProgressaoProposicao',
          filtro
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }
}
