import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService extends ApiConfig {

  constructor(private http: HttpClient) {
    super();
  }

  listarRecente() {
    try {
      return new Promise(async (resolve) => {

        this.http.get('https://api.legislapp.com.br/Noticia/listarRecentes')
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Noticia/Obter?Id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarPorProjeto(idProjetoLista) {
    try {
      return new Promise(async (resolve) => {

        this.http.post('https://api.legislapp.com.br/Noticia/ListarPorProjetoLei',
          idProjetoLista)
          .subscribe(data => {
            resolve(data);
          }, error => {
          });
      });
    } catch (error) {
      alert(error);
    }
  }
}

