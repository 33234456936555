import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class ProjetoVisualizadoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }

  listar(login) {
    try {
      return new Promise(async (resolve) => {
        this.http
          .get('https://api.legislapp.com.br/ProjetoVisualizadoUsuario/ListarPorUsuarioGet?usuario=' + login)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => { }
          );
      });
    } catch (error) {
      alert(error);
    }
  }
}
