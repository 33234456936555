import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UsuarioService } from '../api/usuario.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Platform,
  ModalController,
  LoadingController,
  AlertController,
  ToastController,
} from '@ionic/angular';
import { BuscaService } from '../api/busca.service';
import { ParlamentarService } from '../api/parlamentar.service';
import { ProjetoService } from '../api/projeto.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { ClienteService } from '../api/cliente-service.service';
import { RadarClienteService } from '../api/radar-cliente-service.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Component({
  selector: 'app-selecao-usuario-plano',
  templateUrl: './selecao-usuario-plano.page.html',
  styleUrls: ['./selecao-usuario-plano.page.scss'],
})
export class SelecaoUsuarioPlanoPage extends PageBaseService {
  filtro = { Titulo: '' };
  autoCompleteLista: any;
  autoCompleteListaBkp: any;
  isItemAvailable: boolean;
  @Input()
  radar: any;
  usuarioAdicionadoLista: any[];
  clienteLista: any;
  usuario: any;
  plano: any;
  membroLista: any;
  constructor(
    private menuService: MenuService,
    public platform: Platform,
    public modalController: ModalController,
    private radarService: RadarMonitoramentoService,
    private toastController: ToastController,
    private projetoService: ProjetoService,
    private clienteService: ClienteService,
    private radarClienteService: RadarClienteService,
    private usuarioService: UsuarioService,
    private parlamentarService: ParlamentarService,
    private vibration: Vibration,
    public route: ActivatedRoute,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private zone: NgZone,
    protected router: Router,
    private titleService: Title
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  carregarListaPlano() {
    this.usuario = this.usuarioService.get();
    this.usuarioService.obterPlano(this.usuario.Id).then((data) => {
      this.plano = data;
      var idMembroLista = this.radar.RadarMembroLista.map((x) => x.IdUsuario);
      this.membroLista = this.plano.ConviteLista;
      if (!this.membroLista) {
        this.usuarioService
          .listarUsuariosPlano(this.usuario.Id)
          .then((data) => {
            this.membroLista = data;
            var usuarioSelecionadoLista = this.membroLista
              .filter((x) => idMembroLista.indexOf(x.Id) >= 0)
              .map((x) => x.Email.toLowerCase());
            this.membroLista.map((x) => {
              x.Selecionado =
                usuarioSelecionadoLista.indexOf(x.Email.toLowerCase()) >= 0;
            });
          });
      } else {
        var usuarioSelecionadoLista = this.plano.UsuarioPlanoLista.filter(
          (x) => idMembroLista.indexOf(x.Id) >= 0
        ).map((x) => x.Email.toLowerCase());
        this.membroLista.map((x) => {
          x.Selecionado =
            usuarioSelecionadoLista.indexOf(x.Email.toLowerCase()) >= 0;
        });
      }
    });
  }
  membroClick(membro) {
    membro.Selecionado = !membro.Selecionado;
    if (membro.Selecionado) {
      var enviarEmail = confirm('Deseja notificar o ' + membro.Email + '?');
      if (enviarEmail) {
        this.exibirLoading('Enviando e-mail...');
        this.usuarioService
          .notificarIngressoRadar(membro.Email, this.radar.Id)
          .then(() => {
            this.ocultarLoading();
          });
      }
      this.usuarioService
        .ingressarRadar({
          Email: membro.Email,
          IdRadar: this.radar.Id,
        })
        .then(() => {
          this.vibration.vibrate([2000, 1000, 2000]);
          this.presentToast('Membro adicionado com sucesso!');
          this.radarService.limparCache();
          localStorage.setItem('RADAR_MODIFICADO', 'true');
        });
    } else {
      this.usuarioService
        .egressarRadar({
          Email: membro.Email,
          IdRadar: this.radar.Id,
        })
        .then(() => {
          this.vibration.vibrate([2000, 1000, 2000]);
          this.presentToast('Membro removido com sucesso!');
          this.radarService.limparCache();
          localStorage.setItem('RADAR_MODIFICADO', 'true');
        });
    }
  }
  ngOnInit() {
    this.carregarAutoComplete();
    this.compartilharPlanoClienteChange();
    this.radar.CompartilharPlano = this.radar.IdPlano ? true : false;
    if (this.radar.CompartilharPlano) this.carregarListaPlano();
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: 'primary',
      cssClass: 'toast-radar',
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }
  compartilharPlanoChange($event:any) {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((plano: any) => {
        if (!$event.currentTarget.checked) {
          this.carregarListaPlano();
          this.radar.IdPlano = plano.Id;
        } else {
          this.radar.IdPlano = null;          
        }
        this.radarService.alterar(this.radar).then(async () => {
          const toast = await this.toastController.create({
            message: '',
            duration: 4000,
            position: 'bottom',
            color: `${this.radar.IdPlano ? 'primary' : 'danger'}`,
            cssClass: 'toast-radar',
            buttons: [
              {
                side: 'start',
                icon: 'person-outline',
                text: `Time ${
                  this.radar.IdPlano ? 'incluído' : 'Removido'
                } no radar ${this.radar.Titulo}`,
              },
            ],
          });
          toast.onDidDismiss().then((e) => {});
          toast.present();
        });
      });
  }

  clienteClick(cliente: any) {
    cliente.Selecionado = !cliente.Selecionado;
    var model = {
      IdCliente: cliente.Id,
      IdRadar: this.radar.Id,
    };
    if (cliente.Selecionado) {
      this.radarClienteService.inserir(model).then((data: any) => {
        this.presentToast(
          cliente.Nome + ' adicionado ao radar ' + this.radar.Titulo
        );
      });
    } else {
      this.radarClienteService.deletar(model).then((data: any) => {
        this.presentToast(
          cliente.Nome + ' removido do radar ' + this.radar.Titulo
        );
      });
    }
    this.radarService.limparCache();
    localStorage.setItem('RADAR_MODIFICADO', 'true');
  }

  compartilharPlanoClienteChange(event = null) {
    this.clienteService
      .listarPorUsuarioAdmin(this.usuarioService.get().Id)
      .then((data: any) => {
        this.radarClienteService
          .listarIdSelecionadoPorRadar(this.radar.Id)
          .then((idSelecionado: any) => {
            this.clienteLista = data;
            this.clienteLista.map(
              (x) => (x.Selecionado = idSelecionado.indexOf(x.Id) > -1)
            );
            this.radar.CompartilharCliente =
              this.clienteLista.filter((x) => x.Selecionado).length > 0 ||
              event;
            // if (this.radar.CompartilharCliente)
            //   this.compartilharPlanoClienteChange();
          });
      });
  }
  selecionarTodosMembros() {
    this.membroLista = this.membroLista.map((x) => {
      x.Selecionado = !x.Selecionado;
      return x;
    });
    this.membroLista
      .filter((x) => x.Selecionado)
      .forEach((element) => {
        element.Selecionado = !element.Selecionado;
        this.membroClick(element);
      });
  }
  carregarAutoComplete() {
    this.usuarioService
      .listarUsuariosPlano(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        this.autoCompleteLista = data;
        this.autoCompleteListaBkp = data;
      });
  }
  fechar() {
    this.modalController.dismiss({});
  }
  autoCompleteClick(usuario) {
    if (this.radar.IdsUsuarioMembro) this.radar.IdsUsuarioMembro = '';
    this.radar.IdsUsuarioMembro =
      this.radar.IdsUsuarioMembro + usuario.Id + ', ';
    this.radarService.alterar(this.radar).then(async () => {
      const toast = await this.toastController.create({
        message: '',
        duration: 4000,
        position: 'bottom',
        color: 'primary',
        cssClass: 'toast-radar',
        buttons: [
          {
            side: 'start',
            icon: 'radio-outline',
            text:
              'Usuário adicionado com sucesso no radar ' + this.radar.Titulo,
          },
        ],
      });
      toast.onDidDismiss().then((e) => {});
      toast.present();
    });
  }

  getItemAutocomplete(ev: any) {
    if (ev.target.value.length >= 3) {
      // Reset items back to all of the items
      this.autoCompleteLista = this.autoCompleteListaBkp;

      // set val to the value of the searchbar
      const val = ev.target.value;

      // if the value is an empty string don't filter the items
      if (val && val.trim() !== '') {
        this.isItemAvailable = true;
        this.autoCompleteLista = this.autoCompleteLista
          .filter((item) => {
            return (
              item.Nome.toLowerCase().substring(0, ev.target.value.length) ==
              val.toLowerCase()
            );
          })
          .slice(0, 3);
      } else {
        this.autoCompleteLista = [
          { Nome: 'Todos os membros da equipe', Email: '' },
        ];
        this.isItemAvailable = true;
      }
    } else {
      this.autoCompleteLista = [
        { Nome: 'Todos os membros da equipe', Email: '' },
      ];
      this.isItemAvailable = true;
    }
  }
}
