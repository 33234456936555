// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight {
  --ion-item-background:#92dcff;
}`, "",{"version":3,"sources":["webpack://./src/app/selecao-usuario-plano/selecao-usuario-plano.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/selecao-usuario-plano/selecao-usuario-plano.page.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;ACCJ","sourcesContent":[".highlight {\r\n    --ion-item-background:#92dcff;\r\n }",".highlight {\n  --ion-item-background:#92dcff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
