import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class TwitterService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  buscar(idProjetoLista, tags) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Twitter/Buscar', { idProjetoLista, tags })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  obter(id) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Twitter/Obter?Id='+id)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarRecentes() {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Twitter/ListarRecentes')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
  listarPorTag(tags) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Twitter/listarPorTag?' + tags)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => { }
        );
    });
  }
}
